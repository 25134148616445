import GeoAPIRequest from '../api/GeoAPIRequest'
import * as Turf from '@turf/turf'

export default class FeatureRepo {
  constructor () {
    this._featureCache = {}
    this._layerCache = {}

    this._indexes = {}
  }

  getLayerAndResolution (layer, resolution) {
    return this._layerCache[layer] && this._layerCache[layer][resolution]
  }

  getFeature (id) {
    return this._featureCache[id]
  }

  put (feature) {
    let layer = feature.properties.layer
    let resolution = feature.properties.resolution

    if (!this._layerCache[layer]) this._layerCache[layer] = {}
    if (!this._layerCache[layer][resolution]) this._layerCache[layer][resolution] = {}

    this._layerCache[layer][resolution][feature.id] = feature
    this._featureCache[feature.id] = feature
  }

  async populate (mapDataRequest) {
    let indexHit = this._indexContains(
      mapDataRequest._layer,
      mapDataRequest._resolution,
      mapDataRequest.boundsAsPolygon()
    )

    if (indexHit) return false

    // TODO slice to only the region of data we need
    let resp = await GeoAPIRequest.execute(mapDataRequest)

    let dataUpdated = resp.length > 0

    resp.forEach(d => {
      let data = {
        id: d.managed_id,
        type: 'Feature',
        geometry: d.geom,
        properties: {
          label: d.label,
          shortLabel: d.short_label,
          layer: mapDataRequest._layer,
          resolution: mapDataRequest._resolution,
          metadata: d.metadata
        }
      }

      data.orig_properties = data.properties

      this.put(data)
    })

    this._populateIndex(
      mapDataRequest._layer,
      mapDataRequest._resolution,
      mapDataRequest.boundsAsPolygon()
    )

    return dataUpdated
  }

  _indexContains (layer, resolution, poly) {
    let index = this._getIndex(layer, resolution)

    let hit = false
    if (index != null) {
      Turf.flattenEach(index, g => {
        if (Turf.booleanContains(g, poly)) {
          hit = true
        }
      })
    }

    return hit
  }

  _populateIndex (layer, resolution, poly) {
    let oldIndex = this._getIndex(layer, resolution)
    this._setIndex(layer, resolution, oldIndex ? Turf.union(oldIndex, poly) : poly)
  }

  _getIndex (layer, resolution) {
    if (!this._indexes[layer]) this._indexes[layer] = {}

    if (!this._indexes[layer][resolution]) this._indexes[layer][resolution] = null

    return this._indexes[layer][resolution]
  }

  _setIndex (layer, resolution, index) {
    this._indexes[layer][resolution] = index
  }
}
