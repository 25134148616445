import { Controller } from 'stimulus'
import Map from 'lib/Map'
import PubSub from 'pubsub-js'
import StateStore from 'lib/StateStore'

export default class extends Controller {
  static targets = []

  initialize () {

  }

  connect () {
    this.map = new Map(this.element, this.data.get('dataUrl'), this)

    window.addEventListener('resize', () => this.map.resizeMapPanel())
    PubSub.subscribe('ntg.resize', () => this.map.resizeMapPanel())

    PubSub.subscribe('ntg.state.measures', (msg, measures) => {
      this.map.measuresSelected(measures.selection)
    })

    PubSub.subscribe('ntg.state.layers', (msg, layers) => {
      let selection = layers.selection
      StateStore.merge('map', { selection: null }) // Clear the selection
      this.map.layerSelected(selection)
    })

    this.map.setup()

    let layerSelection = StateStore.get('layers')?.selection
    if (layerSelection) this.map.layerSelected(layerSelection)

    let measuresSelection = StateStore.get('measures')?.selection
    if (measuresSelection) this.map.measuresSelected(measuresSelection)
  }

  featureHovered (feature, map, popup, event) {
    if (!feature) return

    PubSub.publish('ntg.map.hovered', {
      feature: feature,
      map: map,
      event: event,
      popup: popup
    })
  }

  featureClicked (feature, resetUS) {
    let selection = null

    if (feature && !resetUS) {
      selection = {
        id: feature.id,
        label: feature.properties.label,
        shortLabel: feature.properties.shortLabel,
        metadata: JSON.parse(feature.properties.metadata ?? '{}'),
        layer: feature.properties.layer
      }
    }

    StateStore.merge('map', { selection: selection })
  }
}
