import _ from 'lodash'
import * as d3 from 'd3'
import * as d3plus from 'd3plus-text'
import chroma from 'chroma-js'
import StateStore from 'lib/StateStore'

export class BarChart {
  constructor (container, options) {
    this.container = d3.select(container)
    _.assign(this, new BarConfig(options))

    this.formatAsPercentage = d3.format('.0%')
  }

  buildChart (data) {
    const me = this

    const height = Math.ceil(data.length * (this.barHeight + this.barPadding))
    this.height = height
    const contWidth = this.container.node().getBoundingClientRect().width
    const width = contWidth - this.margin.left - this.margin.right

    if (width <= 0) {
      throw new Error('Chart width must be > 0, ensure the container is attached to the DOM and displayed')
    }

    data = this._prepData(data)

    let tooltip = (function (t) {
      if (t.description.trim().length) {
        return `<i class='fa fa-info-circle'
         data-controller='tooltip'
         data-tooltip-classes='description-tooltip'
         data-tooltip-title='${t.title}'
         data-tooltip-body='${t.description}'
         data-action='mouseover->tooltip#show mouseout->tooltip#hide'
      ></i>`
      } else {
        return ''
      }
    }(this))

    this.container.append('h6')
      .html(this.title + tooltip)

    this.xScale = d3.scaleLinear()
      .domain([0, 1])
      .rangeRound([0, width - 1])

    this.yScale = d3.scaleBand()
      .domain(data.map(d => d.displayLabel))
      .rangeRound([0, height])
      .padding(this.barPadding)

    this.xAxis = d3.axisBottom()
      .scale(this.xScale)
      .ticks(this.tickCount)
      .tickFormat(this.formatAsPercentage)
      .tickSizeOuter(0)
      .tickSizeInner(0)
      .tickPadding(this.tickPadding)

    this.yAxis = d3.axisLeft()
      .scale(this.yScale)
      .tickSizeOuter(0)
      .tickSizeInner(0)
      .tickPadding(this.tickPadding)

    this.chart = this.container
      .append('svg')
      .attr('width', width + this.margin.left + this.margin.right)
      .attr('height', height + this.margin.top + this.margin.bottom)

    this.chart.append('g')
      .attr('class', 'y axis')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
      .call(this.yAxis)
      .selectAll('.tick text')
      .each(function (d) {
        let thisNode = d3.select(this)

        new d3plus.TextBox()
          .data([d])
          .text(d => d)
          .select(this.parentNode)
          .textAnchor('end')
          .verticalAlign('middle')
          .width(me.margin.left - me.tickPadding)
          .x(-me.margin.left)
          .y(-100) // TODO I don't understand why this is needed and really don't understand why it appears to be exactly 100
          .fontFamily(me.fontFamily)
          .fontSize(me.fontSize)
          .lineHeight(me.lineHeight)
          .maxLines(me.maxLines)
          .render()

        thisNode.remove()
      })

    this.chart.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(' + this.margin.left + ',' + (height + this.margin.top) + ')')
      .call(this.xAxis)

    this.chart.append('g')
      .attr('class', 'grid-lines-group')
      .call(this._gridLines.bind(this))
  }

  render (data) {
    let me = this

    data = this._prepData(data)

    this.chart.selectAll('.bar-group')
      .data(data, d => d.valueRef)
      .join(
        enter => {
          let bar = enter
            .append('g')
            .attr('class', 'bar-group')

          bar.append('rect')
            .attr('fill', d => this.barColor)
            .attr('class', 'bar')
            // I'm adding 1 here so the bar doesn't render on top of the axis
            // I would render the axises after the bars to solve this if we didn't need to deal w/ updating bars
            .attr('x', this.margin.left + 1)
            .attr('y', d => this.yScale(d.displayLabel) + this.margin.top)
            .attr('width', 0)
            .attr('height', this.yScale.bandwidth())
            // todo: @mark this probably needs some logic to make sure it doesn't display outside of the window
            .on('mouseover', function (d) {
              d3.select(this).attr('fill', function () {
                return me.barHoverColor
              })

              d3.select('#tooltip')
                .select('#value')
                .text(me.formatAsPercentage(d.measure.value))

              let tooltip = document.getElementById('tooltip')
              let ttHeight = tooltip.getBoundingClientRect().height
              let xPosition = d3.event.pageX + 15
              let yPosition = d3.event.pageY - (Math.floor(ttHeight / 2))

              d3.select('#tooltip')
                .style('left', xPosition + 'px')
                .style('top', yPosition + 'px')
                .classed('d-none', false)
            })
            .on('mousemove', function () {
              let tooltip = document.getElementById('tooltip')
              let ttHeight = tooltip.getBoundingClientRect().height
              let xPosition = d3.event.pageX + 15
              let yPosition = d3.event.pageY - (Math.floor(ttHeight / 2))

              d3.select('#tooltip')
                .style('left', xPosition + 'px')
                .style('top', yPosition + 'px')
            })
            .on('mouseout', function () {
              d3.select(this).attr('fill', d => me.barColor)
              d3.select('#tooltip').classed('d-none', true)
            })
            .transition()
            .duration(1000)
            .attr('width', d => this.xScale(d.measure.value))

          return bar
        },
        update => {
          update.select('rect')
            .transition().duration(1000)
            .attr('width', d => this.xScale(d.measure.value))
          return update
        }
      )
  }

  resize () {
    let contWidth = this.container.node().getBoundingClientRect().width
    let width = contWidth - this.margin.left - this.margin.right

    if (width <= 0) {
      throw new Error('Chart width must be > 0, ensure the container is attached to the DOM and displayed')
    }

    this.container.select('svg')
      .attr('width', width + this.margin.left + this.margin.right)
    this.xScale.rangeRound([0, width - 1])
    this.chart.select('.x.axis').call(this.xAxis)
    this.chart.select('.grid-lines-group').call(this._gridLines.bind(this))
    this.chart.selectAll('.bar-group rect').attr('width', d => this.xScale(d.measure.value))
  }

  teardown () {

  }

  _prepData (data) {
    data = data.sort((a, b) => a.order - b.order)

    // TODO how do we want this to work?
    data = data.sort((a, b) => {
      return this.sort.indexOf(a.label) - this.sort.indexOf(b.label)
    })

    data.forEach(d => { d.displayLabel = d.shortLabel || d.label })

    let total = data.reduce((sum, d) => sum + d.measure.value, 0)

    data.forEach(d => { d.measure.value = d.measure.value / total })

    return data
  }

  _gridLines (sel) {
    sel.selectAll('line')
      .data(this.xScale.ticks(this.tickCount).slice(1))
      .join('line')
      .attr('class', 'vertical-grid-line')
      .style('stroke-dasharray', ('3, 3'))
      .attr('y1', this.margin.top)
      .attr('y2', this.height + this.margin.top)
      .attr('x1', (d) => this.xScale(d) + this.margin.left)
      .attr('x2', (d) => this.xScale(d) + this.margin.left)
  }
}

export class BarConfig {
  constructor (options) {
    let presentationMode = StateStore.get('mode')?.name === 'presentation'

    this.barHeight = _.get(options, 'barHeight', presentationMode ? 40 : 30)
    this.barPadding = _.get(options, 'barPadding', 0.2)
    this.margin = _.defaults(options.margin,
      presentationMode
        ? { top: 0, right: 25, bottom: 30, left: 150 }
        : { top: 0, right: 15, bottom: 30, left: 90 }
    )
    this.tickCount = _.get(options, 'tickCount', 5)
    this.tickPadding = _.get(options, 'tickPadding', 5)
    this.barColor = _.get(options, 'barColor', '#00A0B0')
    this.barHoverColor = _.get(options, 'barHoverColor', chroma(this.barColor).darken().hex())
    this.title = _.get(options, 'title', '')
    this.description = _.get(options, 'description', '')
    this.dimension = _.get(options, 'dimension')
    this.sort = _.get(options, 'sort', [])
    this.fontFamily = _.get(options, 'fontFamily', ['proxima-nova', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'])
    this.fontSize = _.get(options, 'fontSize', presentationMode ? 18 : 13)
    this.lineHeight = _.get(options, 'lineHeight', this.fontSize * 1.1)
    this.maxLines = _.get(options, 'lineHeight', 2)
  }
}
