import mapboxgl from 'mapbox-gl'
import MapData from './MapData'
import MapDataRequest from './MapDataRequest'

const MAP_RESOLUTION = 'medium_low'

export default class SubMap {
  constructor ({ container, bounds, mapTileUrl, dataUrl }) {
    this.container = container
    this.bounds = bounds
    this.mapTileUrl = mapTileUrl
    this.dataUrl = dataUrl

    this.map = new mapboxgl.Map({
      container: this.container,
      style: this.mapTileUrl,
      bounds: this.bounds,
      trackResize: false
    })

    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: 'bottom'
    })

    this._setupControls()

    this._mapData = new MapData()

    // Setup and observer to let us know when we become visible so we can delay data loading until then.
    let observer = new window.MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (
          mutation.attributeName === 'class' &&
          mutation.oldValue.includes('invisible') &&
          !mutation.target.classList.contains('invisible')
        ) {
          this._onBecameVisible()
        }
      })
    })
    observer.observe(this.container, { attributes: true, attributeOldValue: true })
  }

  async updateData (layer, measures) {
    this.currentLayer = layer
    this.currentMeasures = measures

    if (this._isHidden()) return

    let req = new MapDataRequest(this.dataUrl)
      .measureNames(measures.map(m => m.name))
      .layer(layer.name)

    return this._mapData.executeData(req)
  }

  async updateGeo (layer) {
    this.currentLayer = layer

    if (this._isHidden()) return

    let bounds = this.map.getBounds()

    let req = new MapDataRequest(this.dataUrl)
      .resolution(MAP_RESOLUTION)
      .bounds(bounds.getSouthWest(), bounds.getNorthEast())
      .layer(layer.name)

    return this._mapData.executeGeos(req)
  }

  async updateAll (layer, measures) {
    this.currentLayer = layer
    this.currentMeasures = measures

    if (this._isHidden()) return

    return Promise.all([
      this.updateGeo(layer),
      this.updateData(layer, measures)
    ]).then(() => this._update(layer, measures))
  }

  resetToBounds () {
    this.map.resize()
    this.map.fitBounds(this.bounds, { linear: true })
  }

  _update (layer) {
    let [geoData] = this._mapData.mapData(layer.name, MAP_RESOLUTION)

    this.map.getSource('geo').setData({
      type: 'FeatureCollection',
      features: geoData
    })
  }

  _setupControls () {
    this.map.scrollZoom.disable()
    this.map.doubleClickZoom.disable()
    this.map.touchZoomRotate.disableRotation()
    this.map.dragRotate.disable()
    this.map.dragPan.disable()
  }

  _isVisible () {
    return this.container.offsetHeight !== 0 && window.getComputedStyle(this.container).visibility !== 'hidden'
  }

  _isHidden () {
    return !this._isVisible()
  }

  _onBecameVisible () {
    if (this.currentLayer && this.currentMeasures) {
      this.updateAll(this.currentLayer, this.currentMeasures)
    }
  }
}
