import DataRepo from './DataRepo'
import FeatureRepo from './FeatureRepo'
import _ from 'lodash'
import * as d3 from 'd3'
import chroma from 'chroma-js'
import DataRecord from './DataRecord'

// Colors used for the Map
export const POSITIVE_COLORS = ['#dbf0f9', 'skyblue', '#58A0CE', '#1360A6', '#07306B'] // last is most positive
export const NEGATIVE_COLORS = ['#fc5503', '#fd7634', '#feaa81', '#ffdbca', '#fff8f4'] // first is most negative
export const CENTER_COLOR = 'white'

export default class MapData {
  constructor () {
    this._featureRepo = new FeatureRepo()
    this._dataRepo = new DataRepo()
  }

  mapData (layer, resolution) {
    let featureData = this._featureRepo.getLayerAndResolution(layer, resolution)

    if (!featureData) throw new Error('No data available for layer/resolution')

    return this._hydrateMapData(Object.values(featureData), layer)
  }

  executeGeos (mapDataRequest) {
    return this._featureRepo.populate(mapDataRequest)
  }

  executeData (mapDataRequest) {
    return this._dataRepo.populate(mapDataRequest)
  }

  _hydrateMapData (geos, layer) {
    let [data, summary] = this._prepareLayerData(layer)

    let missingDataRecord = this._missingDataRecord(data, layer)

    let geoData = geos.map(p => {
      let rec = data[p.id]

      if (rec) {
        p.properties = Object.assign({}, p.orig_properties, rec.toMapProperties())
      } else {
        p.properties = Object.assign({}, p.orig_properties, missingDataRecord.toMapProperties())
      }

      return p
    })

    return [geoData, summary]
  }

  // Create a record that will be used for Geos that have no matching data record available
  _missingDataRecord (data, layer) {
    if (data.length === 0) return new DataRecord({ id: null, layerName: layer, measures: [], sanitized: true })

    let example = Object.values(data)[0]

    let copy = _.cloneDeep(example)

    copy.value = 0
    copy.mapValue = 0
    copy.fillColor = 'white'
    copy.sanitized = true

    copy.measures.forEach(m => { m.value = 0 })

    return copy
  }

  _prepareLayerData (layerName) {
    let layer = this._dataRepo.getLayer(layerName)
    let data = Object.values(layer?.geos || {})

    if (data.length === 0) {
      return [
        data,
        {
          minValue: 0,
          maxValue: 0,
          colorScale: [],
          domain: []
        }
      ]
    }

    let meta = layer.meta

    let isDiff = data[0].measures.length > 1

    data.forEach(d => {
      if (isDiff) {
        let m1 = d.measures[1]
        let m0 = d.measures[0]
        let v1 = m1.value === 0 || m1.value == null ? m1.sanitizationThreshold : m1.value
        let v0 = m0.value === 0 || m0.value == null ? m0.sanitizationThreshold : m0.value

        d.mapValue = (v1 - v0) / v0 * 100.0
      } else {
        let m0 = d.measures[0]
        let v0 = m0.value === 0 || m0.value == null ? 0 : m0.value

        d.mapValue = v0 / meta.measures[m0.name].total * 100.0
      }
    })

    let dataSummary = this._summaryData(data.map(d => d.mapValue || 0))

    let colorScale = chroma.scale(dataSummary.colorScale).domain(dataSummary.domain)

    data.forEach(d => { d.fillColor = colorScale(d.mapValue).css() })

    return [_.keyBy(data, 'id'), dataSummary]
  }

  _summaryData (data) {
    let [minValue, maxValue] = d3.extent(data)

    // This intentionally drops out values of 0, they will always be set to the
    // the center color
    let partitionedData = _.groupBy(data, e => e > 0 ? 1 : e === 0 ? 0 : -1)
    let posData = partitionedData[1] ?? []
    let negData = partitionedData[-1] ?? []

    let positiveQuintiles = posData.length > 0 ? chroma.limits(posData, 'q', POSITIVE_COLORS.length - 1) : []
    let negativeQuintiles = negData.length > 0 ? chroma.limits(negData, 'q', NEGATIVE_COLORS.length - 1) : []

    let scale = []
    let domain = []

    if (negativeQuintiles.length > 0) {
      let length = Math.min(NEGATIVE_COLORS.length, negativeQuintiles.length)
      scale = scale.concat(NEGATIVE_COLORS.slice(0, length))
      domain = domain.concat(negativeQuintiles.slice(0, length))
    }

    scale.push(CENTER_COLOR)
    domain.push(0)

    if (positiveQuintiles.length > 0) {
      let length = Math.min(POSITIVE_COLORS.length, positiveQuintiles.length)
      scale = scale.concat(POSITIVE_COLORS.slice(0, length))
      domain = domain.concat(positiveQuintiles.slice(0, length))
    }

    return {
      minValue: minValue,
      maxValue: maxValue,
      colorScale: scale,
      domain: domain
    }
  }
}
