export default class Measure {
  constructor ({ name, label, description, value, sanitizationThreshold }) {
    this.name = name
    this.label = label
    this.description = description
    this.value = value
    this.sanitizationThreshold = sanitizationThreshold
  }

  static fromAPI (obj) {
    return new Measure({
      name: obj.name,
      label: obj.label,
      description: obj.description,
      sanitizationThreshold: obj.sanitization_threshold,
      value: obj.value
    })
  }
}
