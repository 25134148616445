import MapAPIRequest from 'lib/api/MapAPIRequest'
import DataRecord from 'lib/map/DataRecord'
import Layer from 'lib/map/Layer'

export default class DataRepo {
  constructor () {
    this._recCache = {}
    this._layerCache = {}
  }

  getLayer (layer) {
    return this._layerCache[layer]
  }

  getMeta (layer) {
    return this._layerCache[layer]?.meta
  }

  getRecord (id) {
    return this._recCache[id]
  }

  put (rec) {
    this._recCache[rec.id] = rec

    if (!this._layerCache[rec.layerName]) this._layerCache[rec.layerName] = new Layer()

    this._layerCache[rec.layerName].geos[rec.id] = rec

    rec.layer = this._layerCache[rec.layerName]
  }

  async populate (mapDataRequest) {
    let resp = await MapAPIRequest.execute(mapDataRequest)

    if (resp.data.length === 0) return false

    resp.data.map(d => this.put(DataRecord.fromAPI(d)))

    this.getLayer(resp.data[0].layer).meta = resp.meta

    return true
  }
}
