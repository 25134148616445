export default class GeoAPIRequest {
  static PATH = '/geos'

  constructor () {
    this._resolution = null
    this._swLngLat = null
    this._neLngLat = null
    this._layer = null
  }

  execute () {
    let url = new URL(window.location.href)
    url.pathname = GeoAPIRequest.PATH
    let params = new URLSearchParams()

    params.append('resolution', this._resolution)
    params.append('swlat', this._swLngLat.lat)
    params.append('swlng', this._swLngLat.lng)
    params.append('nelat', this._neLngLat.lat)
    params.append('nelng', this._neLngLat.lng)
    params.append('layer', this._layer)

    url.search = params.toString()

    return window.fetch(url)
      .then(response => response.json())
      .then(resp => {
        if (resp.error || !Array.isArray(resp)) {
          throw new Error(resp.error ?? 'Unknown Error')
        }

        return resp
      })
  }

  resolution (resolution) {
    this._resolution = resolution

    return this
  }

  bounds (sw, ne) {
    this._swLngLat = sw
    this._neLngLat = ne

    return this
  }

  layer (layer) {
    this._layer = layer

    return this
  }

  static execute (mapDataRequest) {
    return new GeoAPIRequest()
      .resolution(mapDataRequest._resolution)
      .bounds(mapDataRequest._swLngLat, mapDataRequest._neLngLat)
      .layer(mapDataRequest._layer)
      .execute()
  }
}
