import { Controller } from 'stimulus'
import * as Utils from 'lib/Utils'

export default class TooltipController extends Controller {
  initialize () {}

  connect () {
    this.data.set('id', Math.random().toString(36).substr(2, 9))
    this.outerClass = 'tooltip-outer-container'
    this.innerClass = 'tooltip-inner-container'
    this.arrowClass = 'arrow-left'
  }

  show () {
    this.tooltipEle.remove()
    this._buildTooltip()
  }

  hide () {
    this.tooltipEle.remove()
  }

  _buildTooltip () {
    let tt = Utils.docFragment(`
      <div id="${this.id}" class="${this.outerClasses}">
        <div class="${this.arrowClass}"></div>
        <div class="${this.innerClass}">
            <h5>${this.title}</h5>
            <p>${this.body}</p>
        </div>
      </div>
    `).children[0]

    document.body.appendChild(tt)

    let elePos = this.element.getBoundingClientRect()

    let ttOffset = Math.floor(tt.getBoundingClientRect().height / 2)
    let eleOffset = Math.floor(this.element.getBoundingClientRect().height / 2)
    let offset = ttOffset - eleOffset

    tt.style.top = `${window.scrollY + elePos.top - offset}px`
    tt.style.left = `${window.scrollX + elePos.left + elePos.width + 10}px`

    let ttBounds = tt.getBoundingClientRect()

    // Check if the tooltip ends up above the viewport, if so push it down.
    if (ttBounds.top < 0) {
      tt.style.top = `${parseInt(tt.style.top, 10) - ttBounds.top}px`
    }

    return tt
  }

  get title () {
    return this.data.get('title')
  }

  get body () {
    return this.data.get('body')
  }

  get outerClasses () {
    return this.outerClass + ' ' + this.data.get('classes')
  }

  get id () {
    return this.data.get('id')
  }

  get tooltipEle () {
    return document.getElementById(this.id) || document.createElement('div')
  }
}
