import { Controller } from 'stimulus'
import { BarChart } from 'lib/charts/Bar'
import { GroupedBarChart } from 'lib/charts/GroupedBar'
import { RadarChart } from 'lib/charts/Radar'
import { formatWholeNumber, formatMinimalDisplayNumber } from 'lib/Utils'
import PubSub from 'pubsub-js'
import _ from 'lodash'
import $ from 'jquery'
import StateStore from 'lib/StateStore'

const CHART_TYPE_MAPPING = {
  bar: BarChart,
  bar_diff: GroupedBarChart,
  radar: RadarChart
}

export default class extends Controller {
  static targets = ['selectionTitle', 'selectionStats']

  selection = null
  measures = []
  charts = []

  get componentName () {
    return this.data.get('componentName')
  }

  get dataUrl () {
    return this.data.get('dataUrl')
  }

  get statLabel () {
    return this.data.get('statLabel')
  }

  initialize () {}

  connect () {
    PubSub.subscribe('ntg.state.map', (msg, map) => {
      if (_.isEqual(map.selection, this.selection)) return

      if (map.selection) {
        this.updateSelection(map.selection)
      } else {
        this.clearSelection()
      }
    })

    PubSub.subscribe('ntg.state.measures', (msg, measures) => {
      this.updateMeasures(measures.selection)
    })

    PubSub.subscribe('ntg.resize', () => this.resizeCharts())
    window.addEventListener('resize', () => this.resizeCharts())

    let measuresSelection = StateStore.get('measures')?.selection
    if (measuresSelection) this.measures = measuresSelection

    let mapSelection = StateStore.get('map')?.selection
    if (mapSelection) this.selection = mapSelection

    if (!_.isEmpty(this.measures) && this.selection !== undefined) this.loadCharts()

    // Currently there are various configuration options in the charts that are dependent on presentation mode
    // and are only checked / setup when the chart is first created. Ideally we would update these values when
    // presentation mode changes. However this would be a minor optimization so for now we just destroy and rebuild
    // the charts when presentation mode changes
    // TODO consider hooking this event in the charts themselves and updating values, there are edge cases with this though.
    PubSub.subscribe('ntg.mode', () => {
      this.charts = []
      $(this.element).find('.charts').html('')
      this.loadCharts()
    })
  }

  updateSelection (selection) {
    this.selection = selection
    this.loadCharts()
  }

  clearSelection () {
    this.selection = null
    this.loadCharts()
  }

  updateSelectionTitle (title) {
    this.selectionTitleTarget.textContent = title
  }

  updateSelectionTotal (measures) {
    // Diff view
    if (measures.length > 1) {
      let m1Label = measures[0].label
      let m1Description = measures[0].description
      let m1Count = measures[0].localTotal
      let m1Total = measures[0].total
      let m1PercTotal = ((m1Count / m1Total) * 100.0).toFixed(0)

      let m1Tooltip = (function () {
        if (m1Description.trim().length) {
          return `<i class='fa fa-info-circle'
           data-controller='tooltip'
           data-tooltip-classes='description-tooltip'
           data-tooltip-title='${m1Label}'
           data-tooltip-body='${m1Description}'
           data-action='mouseover->tooltip#show mouseout->tooltip#hide'
        ></i>`
        } else {
          return ''
        }
      }())

      let m2Label = measures[1].label
      let m2Description = measures[1].description
      let m2Count = measures[1].localTotal
      let m2Total = measures[1].total
      let m2PercTotal = ((m2Count / m2Total) * 100.0).toFixed(0)

      let m2Tooltip = (function () {
        if (m1Description.trim().length) {
          return `<i class='fa fa-info-circle'
           data-controller='tooltip'
           data-tooltip-classes='description-tooltip'
           data-tooltip-title='${m2Label}'
           data-tooltip-body='${m2Description}'
           data-action='mouseover->tooltip#show mouseout->tooltip#hide'
        ></i>`
        } else {
          return ''
        }
      }())

      let percShift = formatMinimalDisplayNumber((m2Count - m1Count) / m1Count * 100.0)

      this.selectionStatsTarget.innerHTML = `
           ${m1Label}${m1Tooltip}: <strong>${formatWholeNumber(m1Count)}</strong> of ${formatWholeNumber(m1Total)}  ( <strong>${m1PercTotal}%</strong> )
        <br>
           ${m2Label}${m2Tooltip}: <strong>${formatWholeNumber(m2Count)}</strong> of ${formatWholeNumber(m2Total)}  ( <strong>${m2PercTotal}%</strong> )
        <br>
           Difference +/- : <strong>${percShift}%</strong>
      `
    // Regular view
    } else {
      let label = measures[0].label
      let description = measures[0].description
      let count = measures[0].localTotal
      let total = measures[0].total
      let percTotal = formatMinimalDisplayNumber((count / total) * 100.0)

      let tooltip = (function () {
        if (description.trim().length) {
          return `<i class='fa fa-info-circle'
           data-controller='tooltip'
           data-tooltip-classes='description-tooltip'
           data-tooltip-title='${label}'
           data-tooltip-body='${description}'
           data-action='mouseover->tooltip#show mouseout->tooltip#hide'
        ></i>`
        } else {
          return ''
        }
      }())

      this.selectionStatsTarget.innerHTML = `
        ${label}${tooltip}: <strong>${formatWholeNumber(count)}</strong> of ${formatWholeNumber(total)}  ( <strong>${percTotal}%</strong> )
      `
    }
  }

  updateMeasures (measures) {
    if (measures.length !== this.measures.length) {
      $(this.element).find('.charts').html('')
      this.charts.forEach(c => c.teardown())
      this.charts = []
    }
    this.measures = measures
    this.loadCharts()
  }

  resizeCharts () {
    this.charts.forEach(c => c.resize())
  }

  async loadCharts () {
    let response = await this.requestData()
    let data = response.data

    if (this.charts.length === 0) {
      response.charts.map(chartConfig => {
        let $colContainer = $('<div class="chart-col"></div>')
        let $container = $('<div class="chart"></div>')
        $(this.element).find('.charts').append($colContainer)
        $(this.element).find($colContainer).append($container)

        let chart = new CHART_TYPE_MAPPING[chartConfig.type]($container[0], chartConfig)

        let ourData = this._findOurData(chartConfig, data)

        chart.buildChart(ourData)
        chart.render(ourData)

        this.charts.push(chart)
      })
    } else {
      this.charts.forEach(chart => chart.render(this._findOurData(chart, data)))
    }

    this.updateSelectionTitle(response.title)
    this.updateSelectionTotal(response.meta.measures)
  }

  async requestData () {
    let url = new URL(this.dataUrl)
    let params = new URLSearchParams()

    params.append('component_name', this.componentName)

    if (this.selection) {
      params.append('selection_id', this.selection.id)
    }

    this.measures.forEach(m => params.append('measures[]', m.name))

    url.search = params.toString()

    return window.fetch(url).then(response => response.json())
  }

  filterData (data, dimension) {
    let ourData = data.filter(d => d.dimension.name === dimension)

    if (!this.isDiff()) {
      ourData = ourData.map(d => _(d).omit('measures').merge({ measure: d.measures[0] }).value())
    }

    return ourData
  }

  isDiff () {
    return this.measures.length > 1
  }

  _findOurData (chartConfig, data) {
    let ourData = _.castArray(chartConfig.dimension ?? chartConfig.dimensions).map(d => this.filterData(data, d))

    return ourData.length === 1 ? ourData[0] : ourData
  }
}
