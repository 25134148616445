import * as utils from 'lib/Utils'
import Measure from '../Measure'

export default class DataRecord {
  constructor ({ id, layerName, measures, sanitized } = {}) {
    this.id = id
    this.measures = measures
    this.layerName = layerName
    this.sanitized = sanitized

    this.layer = null

    // Populated during hydration based on summary statistics for the entire layer. See MapData.js
    this.mapValue = null
    this.fillColor = null
  }

  measure (name) {
    return this.measures.find(m => m.name === name)
  }

  toMapProperties () {
    if (!this.layer) throw new Error('Must set layer before conversion to map properties')

    let props = {
      isDiff: this.measures.length > 1,
      sanitized: this.sanitized,
      mapValue: this.mapValue,
      fillColor: this.fillColor
    }

    this.measures.forEach(utils.withIndex((m, i) => {
      props[`measure_${i}_label`] = m.label
      props[`measure_${i}_description`] = m.description
      props[`measure_${i}_value`] = m.value
      props[`measure_${i}_sanitizationThreshold`] = m.sanitizationThreshold
      props[`measure_${i}_total`] = this.layer.meta.measures[m.name].total
    }))

    return props
  }

  static fromAPI (obj) {
    return new DataRecord({
      id: obj.id,
      measures: obj.measures.map(m => Measure.fromAPI(m)),
      layerName: obj.layer,
      sanitized: obj.sanitized
    })
  }
}
