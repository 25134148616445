import Rails from '@rails/ujs'
import Swal from 'sweetalert2'

/*
 * Swoop extensions to Rails-UJS
 */

export function start () {
  Rails.confirm = function (msg, ele) {
    Swal.fire({
      title: msg,
      showCancelButton: true
    }).then(res => {
      if (res.value) {
        // This hackery is because rails-ujs doesn't have an async model for handling confirmation
        let old = Rails.confirm
        Rails.confirm = function () { return true }
        ele.click()
        Rails.confirm = old
      }
    })
  }

  return false
}
