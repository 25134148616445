import * as Turf from '@turf/turf'

export default class MapDataRequest {
  constructor (dataUrl = '') {
    this._dataUrl = dataUrl

    this._resolution = null
    this._swLngLat = null
    this._neLngLat = null
    this._measureNames = null
    this._layer = null
  }

  resolution (resolution) {
    this._resolution = resolution

    return this
  }

  bounds (sw, ne) {
    this._swLngLat = sw
    this._neLngLat = ne

    return this
  }

  measureNames (measureNames) {
    this._measureNames = measureNames

    return this
  }

  layer (layer) {
    this._layer = layer

    return this
  }

  boundsAsPolygon () {
    return Turf.polygon([[
      [this._swLngLat.lng, this._swLngLat.lat],
      [this._swLngLat.lng, this._neLngLat.lat],
      [this._neLngLat.lng, this._neLngLat.lat],
      [this._neLngLat.lng, this._swLngLat.lat],
      [this._swLngLat.lng, this._swLngLat.lat]
    ]])
  }
}
