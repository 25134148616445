import _ from 'lodash'
import * as d3 from 'd3'
import * as d3plus from 'd3plus-text'
import chroma from 'chroma-js'
import StateStore from 'lib/StateStore'

export class GroupedBarChart {
  constructor (container, options) {
    this.container = d3.select(container)
    _.assign(this, new GroupedBarConfig(options))

    this.formatAsPercentage = d3.format('.0%')
  }

  buildChart (data) {
    const me = this

    const height = Math.ceil(data.length * (this.barHeight + this.barPadding))
    this.height = height
    const contWidth = this.container.node().getBoundingClientRect().width
    const width = contWidth - this.margin.left - this.margin.right

    if (width <= 0) {
      throw new Error('Chart width must be > 0, ensure the container is attached to the DOM and displayed')
    }

    data = this._prepData(data)

    let measures = data[0].measures

    // todo: should this be a util function somewhere since it gets reused a bunch?
    let tooltip = (function (t) {
      if (t.description.trim().length) {
        return `<i class='fa fa-info-circle'
         data-controller='tooltip'
         data-tooltip-classes='description-tooltip'
         data-tooltip-title='${t.title}'
         data-tooltip-body='${t.description}'
         data-action='mouseover->tooltip#show mouseout->tooltip#hide'
      ></i>`
      } else {
        return ''
      }
    }(this))

    this.container.append('h6')
      .html(this.title + tooltip)

    this.xScale = d3.scaleLinear()
      .domain([0, 1])
      .rangeRound([0, width - 1])

    this.yGroupScale = d3.scaleBand()
      .domain(data.map(d => d.displayLabel))
      .rangeRound([0, height])
      .paddingInner(this.groupPadding)

    this.yBarScale = d3.scaleBand()
      .domain(measures.map(m => m.label))
      .rangeRound([0, this.yGroupScale.bandwidth()])
      .padding(this.barPadding)

    this.colorScale = d3.scaleOrdinal().range(this.barColors)
    this.hoverColorScale = d3.scaleOrdinal().range(this.barHoverColors)

    this.xAxis = d3.axisBottom()
      .scale(this.xScale)
      .ticks(this.tickCount)
      .tickFormat(this.formatAsPercentage)
      .tickSizeOuter(0)
      .tickSizeInner(0)
      .tickPadding(this.tickPadding)

    this.yAxis = d3.axisLeft()
      .scale(this.yGroupScale)
      .tickSizeOuter(0)
      .tickSizeInner(0)
      .tickPadding(this.tickPadding)

    this.chart = this.container
      .append('svg')
      .attr('width', width + this.margin.left + this.margin.right)
      .attr('height', height + this.margin.top + this.margin.bottom)

    this.chart.append('g')
      .attr('class', 'y axis')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
      .call(this.yAxis)
      .selectAll('.tick text')
      .each(function (d) {
        let thisNode = d3.select(this)

        new d3plus.TextBox()
          .data([d])
          .text(d => d)
          .select(this.parentNode)
          .textAnchor('end')
          .verticalAlign('middle')
          .width(me.margin.left - me.tickPadding)
          .x(-me.margin.left)
          .y(-100) // TODO I don't understand why this is needed and really don't understand why it appears to be exactly 100
          .fontFamily(me.fontFamily)
          .fontSize(me.fontSize)
          .maxLines(me.maxLines)
          .render()

        thisNode.remove()
      })

    this.chart.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(' + this.margin.left + ',' + (height + this.margin.top) + ')')
      .call(this.xAxis)

    this.chart.append('g')
      .attr('class', 'grid-lines-group')
      .call(this._gridLines.bind(this))

    this.legend = this.chart.append('g')
      .classed('legend', true)

    let lg = this.legend
      .selectAll('g')
      .data(measures, m => m.name)
      .enter().append('g')

    lg.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.legendBoxSize)
      .attr('height', this.legendBoxSize)
      .attr('fill', (d, i) => this.colorScale(i))
      .attr('stroke', (d, i) => this.colorScale(i))
      .attr('stroke-width', 2)

    lg.append('text')
      .attr('x', this.legendBoxSize + 4)
      .attr('y', this.legendBoxSize - 1)
      .text(m => m.label)

    this._spaceLegend()
  }

  render (data) {
    let me = this

    data = this._prepData(data)

    let nodeData = d3.local()

    this.chart.selectAll('.bar-group')
      .data(data, d => d.valueRef)
      .join(
        enter => {
          let bar = enter
            .append('g')
            .attr('class', 'bar-group')
            .attr('transform', d => `translate(0, ${this.yGroupScale(d.displayLabel)})`)
          bar
            .selectAll('rect')
            .data(d => d.measures, d => d.name)
            .join('rect')
            .attr('fill', (d, i) => this.colorScale(i))
            .attr('class', 'bar')
            // I'm adding 1 here so the bar doesn't render on top of the axis
            // I would render the axises after the bars to solve this if we didn't need to deal w/ updating bars
            .attr('x', this.margin.left + 1)
            .attr('y', d => this.yBarScale(d.label) + this.margin.top)
            .attr('width', 0)
            .attr('height', this.yBarScale.bandwidth())
            .each(function (d, i) {
              nodeData.set(this, {
                fillColor: d3.select(this).attr('fill'),
                hoverFillColor: me.hoverColorScale(i)
              })
            })
            // todo: @mark this probably needs some logic to make sure it doesn't display outside of the window
            .on('mouseover', function (d) {
              d3.select(this)
                .attr('fill', function () { return nodeData.get(this).hoverFillColor })

              d3.select('#tooltip')
                .select('#value')
                .text(me.formatAsPercentage(d.value))

              let tooltip = document.getElementById('tooltip')
              let ttHeight = tooltip.getBoundingClientRect().height
              let xPosition = d3.event.pageX + 15
              let yPosition = d3.event.pageY - (Math.floor(ttHeight / 2))

              d3.select('#tooltip')
                .style('left', xPosition + 'px')
                .style('top', yPosition + 'px')
                .classed('d-none', false)
            })
            .on('mousemove', function () {
              let tooltip = document.getElementById('tooltip')
              let ttHeight = tooltip.getBoundingClientRect().height
              let xPosition = d3.event.pageX + 15
              let yPosition = d3.event.pageY - (Math.floor(ttHeight / 2))

              d3.select('#tooltip')
                .style('left', xPosition + 'px')
                .style('top', yPosition + 'px')
            })
            .on('mouseout', function () {
              d3.select(this).attr('fill', function () { return nodeData.get(this).fillColor })
              d3.select('#tooltip').classed('d-none', true)
            })
            .transition()
            .duration(1000)
            .attr('width', d => this.xScale(d.value))

          return bar
        },
        update => {
          update
            .selectAll('rect')
            .data(d => d.measures, d => d.name)
            .join('rect')
            .transition().duration(1000)
            .attr('width', d => this.xScale(d.value))

          return update
        }
      )
  }

  resize () {
    const contWidth = this.container.node().getBoundingClientRect().width
    const width = contWidth - this.margin.left - this.margin.right

    if (width <= 0) {
      throw new Error('Chart width must be > 0, ensure the container is attached to the DOM and displayed')
    }

    this.container.select('svg')
      .attr('width', width + this.margin.left + this.margin.right)

    this.xScale.rangeRound([0, width - 1])

    this.chart.select('.x.axis').call(this.xAxis)

    this.chart.select('.grid-lines-group').call(this._gridLines.bind(this))

    this.chart.selectAll('.bar-group rect').attr('width', d => this.xScale(d.value))

    this._spaceLegend()
  }

  teardown () {

  }

  _prepData (data) {
    data = data.sort((a, b) => a.order - b.order)

    // TODO how do we want this to work?
    data = data.sort((a, b) => {
      return this.sort.indexOf(a.label) - this.sort.indexOf(b.label)
    })

    data.forEach(d => { d.displayLabel = d.shortLabel || d.label })

    // Convert to % of each value
    let totals = {}
    data.forEach(d => {
      d.measures.forEach(v => {
        totals[v.name] = totals[v.name] || 0
        totals[v.name] += v.value
      })
    })

    data.forEach(d => {
      d.measures.forEach(v => {
        v.value = v.value / totals[v.name]
      })
    })

    return data
  }

  _spaceLegend () {
    let xPos = this.margin.left
    this.legend.selectAll('g').attr('transform', (d, i, j) => {
      let yPos = this.margin.top + this.legendSpacingTop + this.height + i * j[i].getBBox().height
      return `translate(${xPos}, ${yPos})`
    })
  }

  _gridLines (sel) {
    sel.selectAll('line')
      .data(this.xScale.ticks(this.tickCount).slice(1))
      .join('line')
      .attr('class', 'vertical-grid-line')
      .style('stroke-dasharray', ('3, 3'))
      .attr('y1', this.margin.top)
      .attr('y2', this.height + this.margin.top)
      .attr('x1', (d) => this.xScale(d) + this.margin.left)
      .attr('x2', (d) => this.xScale(d) + this.margin.left)
  }
}

export class GroupedBarConfig {
  constructor (options) {
    let presentationMode = StateStore.get('mode')?.name === 'presentation'

    this.barHeight = _.get(options, 'barHeight', presentationMode ? 55 : 45)
    this.barPadding = _.get(options, 'barPadding', 0.08)
    this.margin = _.defaults(options.margin,
      presentationMode
        ? { top: 0, right: 25, bottom: 90, left: 150 }
        : { top: 0, right: 15, bottom: 60, left: 90 })
    this.tickCount = _.get(options, 'tickCount', 5)
    this.tickPadding = _.get(options, 'tickPadding', 5)
    this.barColors = _.get(options, 'barColors', ['#00A0B0', '#95cacf'])
    this.barHoverColors = _.get(options, 'barHoverColors', this.barColors.map(c => chroma(c).darken().hex()))
    this.title = _.get(options, 'title', '')
    this.description = _.get(options, 'description', '')
    this.dimension = _.get(options, 'dimension')
    this.sort = _.get(options, 'sort', [])
    this.fontFamily = _.get(options, 'fontFamily', ['proxima-nova', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'])
    this.fontSize = _.get(options, 'fontSize', presentationMode ? 18 : 13)
    this.maxLines = _.get(options, 'lineHeight', 2)

    this.groupPadding = _.get(options, 'groupPadding', 0.12)
    this.legendSpacingTop = _.get(options, 'legendSpacing', presentationMode ? 48 : 28)
    this.legendSpacingRight = _.get(options, 'legendSpacing', presentationMode ? 20 : 12)
    this.legendBoxSize = _.get(options, 'legendSpacing', presentationMode ? 15 : 10)
  }
}
