import { Controller } from 'stimulus'
import $ from 'jquery'
import StateStore from 'lib/StateStore'
import Swal from 'sweetalert2'
import * as Utils from 'lib/Utils'
import PubSub from 'pubsub-js'

export default class extends Controller {
  get $element () {
    return this._$element || (this._$element = $(this.element))
  }

  get measureInputsChecked () {
    return [...this.element.querySelectorAll('input.measures:checked')]
  }

  get measureInputs () {
    return [...this.element.querySelectorAll('input.measures')]
  }

  get layerInputsChecked () {
    return [...this.element.querySelectorAll('input.layers:checked')]
  }

  get layerInputs () {
    return [...this.element.querySelectorAll('input.layers')]
  }

  initialize () {

  }

  connect () {
    setTimeout(() => {
      this._setupMeasures()
      this._setupLayers()
    }, 0) // wait for next tick to allow other controllers to connect

    this._setupNonContiguousHideShow()
    this._setupMode()

    let obt = this.close.bind(this)
    $('body').on('touchstart', obt)
  }

  // Called when the app selector dropdown changes
  appSelect (e) {
    window.location.pathname = e.target.options[e.target.options.selectedIndex].value
  }

  isOpen () {
    return !this.isClosed()
  }

  isClosed () {
    return this.$element.hasClass('collapsed')
  }

  open () {
    if (this.isOpen()) return

    this.$element.toggleClass('collapsed')
  }

  close () {
    if (this.isClosed()) return

    this.$element.toggleClass('collapsed')
  }

  toggleMeasure (e) {
    e.stopPropagation()
    let $alert = $('.alert-warning')
    $alert.slideUp()
    let measureInputsChecked = this.measureInputsChecked

    if (measureInputsChecked.length < 1) {
      $alert.find('span').html('Select at least one measure to see graph data')
      $alert.slideDown()
      return
    }

    if (measureInputsChecked.length > 2) {
      e.target.checked = false
      $alert.find('span').html('Only two measures can be selected at once')
      $alert.slideDown()
      return
    }

    this._publishMeasuresChange()
  }

  toggleLayer (e) {
    e.stopPropagation()

    this.layerInputs.forEach(ele => {
      ele.checked = false
    })

    e.target.checked = true

    this._publishLayerChange()
  }

  sharablePrivateLink (e) {
    this._createSharableLink(e, false)
  }

  sharablePublicLink (e) {
    this._createSharableLink(e, true)
  }

  toggleNonContiguous (e) {
    $('#non-contiguous-toggle').toggleClass('off on')
    $('.overlay-map').toggleClass('invisible visible')
    let current = StateStore.get('mode')?.showNonContiguous
    StateStore.merge('mode', { showNonContiguous: !current }, true)
  }

  modeSelect (e) {
    let mode = e.target.value

    document.body.className = document.body.className.replace(/(^|\s)\S+-mode\b/g, '')
    document.body.classList.add(`${mode}-mode`)

    StateStore.merge('mode', { name: mode }, true)
    PubSub.publish('ntg.mode', { name: mode })
    PubSub.publish('ntg.resize', {})
  }

  _setupNonContiguousHideShow () {
    if (StateStore.get('mode')?.showNonContiguous) {
      $('#non-contiguous-toggle').addClass('on').removeClass('off')
      $('.overlay-map').addClass('visible').removeClass('invisible')
    }
  }

  _setupMode () {
    let mode = StateStore.get('mode')?.name
    if (mode) {
      $(`#mode-select option[value="${mode}"]`).attr('selected', true)
      $('body').addClass(`${mode}-mode`)
    }
  }

  _setupMeasures () {
    let selection = StateStore.get('measures')?.selection

    if (selection) {
      this._selectMeasuresByName(selection.map(m => m.name))
    } else {
      if (this.measureInputsChecked.length === 0) {
        this.measureInputs[0].checked = true
        this._publishMeasuresChange()
      }
    }
  }

  _setupLayers () {
    let selection = StateStore.get('layers')?.selection

    if (selection) {
      this._selectLayerByName(selection.name)
    } else {
      if (this.layerInputsChecked.length === 0) {
        this.layerInputs[0].checked = true
        this._selectedLayerEle = this.layerInputs[0]
        this._publishLayerChange()
      }
    }
  }

  _publishLayerChange () {
    let h = this.layerInputsChecked[0]

    StateStore.set('layers', {
      selection: JSON.parse(h.value)
    })
  }

  _publishMeasuresChange () {
    StateStore.set('measures', {
      selection: this.measureInputsChecked.map(m => {
        return {
          name: m.id,
          label: m.value
        }
      })
    })
  }

  _selectLayerByName (name) {
    this.layerInputs.forEach(ele => {
      let data = JSON.parse(ele.value)
      ele.checked = data.name === name
    })
  }

  _selectMeasuresByName (names) {
    this.measureInputs.forEach(ele => {
      ele.checked = names.includes(ele.id)
    })
  }

  _createSharableLink (e, publicLink = false) {
    let url = new URL(window.location.href)
    url.pathname = 's'

    window.fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
      },
      body: JSON.stringify({ url: StateStore.toUrl(), public: publicLink })
    }).then(response => response.json())
      .then(
        d => {
          Utils.copyToClipboard(d.url).then(
            () => {
              Swal.fire({
                title: 'Here is your sharable URL',
                html: `<p class="font-weight-lighter">It has also been copied to your clipboard.</p><a href=${d.url} target="_blank">${d.url}</a>`,
                icon: 'success'
              })
            },
            () => {
              Swal.fire({
                title: 'Here is your sharable URL',
                html: `<a href=${d.url} target="_blank">${d.url}</a>`,
                icon: 'success'
              })
            }
          )
        },
        d => {
          Swal.fire('Unable to created sharable link', '', 'error')
        }
      )
  }
}
