import _ from 'lodash'

export function formatWholeNumber (number) {
  return new Intl.NumberFormat().format(Math.trunc(number))
}

export function formatMinimalDecimals (num, { min = 1, max = 100 } = {}) {
  let strDec = num.toString()
  let match = strDec.match(/-?\d*\.(0+)\d*/)
  let digits = min

  if (match) {
    if (match[1].length + 1 > max) {
      digits = 0
    } else {
      digits = match[1].length + 1
    }
  }

  return num.toFixed(digits)
}

export function formatMinimalDisplayNumber (num) {
  if (Math.abs(num) > 1 || num === 0) {
    return num.toFixed(0)
  } else {
    return formatMinimalDecimals(num, { min: 1, max: 3 })
  }
}

export function withIndex (fn) {
  let index = 0
  return (thing) => fn(thing, index++)
}

export function topN (data, n) {
  let baselineData = data[0]

  let indicesToKeep = _.chain(baselineData)
    .map((item, index) => {
      return { item: item, index: index }
    })
    .sortBy(zipped => -zipped.item.measure.value)
    .take(n)
    .map('index')
    .value()

  return data.map(ds => {
    let partitioned = _.partition(ds, withIndex((item, index) => {
      return _.includes(indicesToKeep, index)
    }))

    return partitioned[0].concat({
      label: 'Others',
      shortLabel: 'Others',
      valueRef: Number.MAX_SAFE_INTEGER.toString(),
      order: Number.MAX_SAFE_INTEGER,
      measure: {
        name: partitioned[0][0].measure.name,
        label: partitioned[0][0].measure.label,
        value: _.sumBy(partitioned[1], 'measure.value') / partitioned[1].length
      }
    })
  })
}

export function isEpsilon (num, tolerance = 1e-10) {
  return Math.abs(num) < tolerance
}

export function copyToClipboard (str) {
  function oldSchool () {
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)

    el.select()
    const result = document.execCommand('copy')
    document.body.removeChild(el)

    return result ? Promise.resolve() : Promise.reject(new Error('Clipboard unsupported'))
  }

  function newSchool () {
    return navigator.clipboard.writeText(str)
  }

  return navigator.clipboard ? newSchool() : oldSchool()
}

export function docFragment (htmlString, allowScripts = false) {
  try {
    let tmpl = document.createElement('template')
    tmpl.innerHTML = htmlString
    let fragment = tmpl.content

    // By default scripts in documentFragments will be unexecutable to fix this they have to be replaced
    if (allowScripts) {
      [...fragment.querySelectorAll('script')].forEach((script) => {
        let fixedScript = document.createElement('script')

        Array.from(script.attributes).forEach(attr => fixedScript.setAttribute(attr.nodeName, attr.nodeValue))

        fixedScript.innerHTML = script.innerHTML

        script.parentNode.replaceChild(fixedScript, script)
      })
    }

    return fragment
  } catch (e) {
    console.error('Unable to parse fragment', htmlString, e)
    return ''
  }
}

// Cycles an Array
// Accepts positive or negative count values to control direction and is not bound to the length
// of the array.
export function rotateArray (arr, count) {
  count -= arr.length * Math.floor(count / arr.length)
  arr.push.apply(arr, arr.splice(0, count))
}
