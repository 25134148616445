import { Controller } from 'stimulus'
import { formatMinimalDisplayNumber } from 'lib/Utils'
import Humanize from 'humanize-plus'
import PubSub from 'pubsub-js'

export default class MapHoverController extends Controller {
  hoverContainer = null

  get hoverLabel () {
    return this.data.get('label')
  }

  initialize () {

  }

  connect () {
    this.hoverContainer = this.element.querySelector('#hover')

    PubSub.subscribe('ntg.map.hovered', this._onHovered.bind(this))
  }

  disconnect () {
    PubSub.unsubscribe('ntg.map.hovered', this._onHovered.bind(this))
  }

  _onHovered (msg, data) {
    this._draw(data)
  }

  _draw ({ feature, map, event, popup }) {
    if (!popup._map) {
      popup.trackPointer().addTo(map)
    }

    popup.setHTML(this._buildContent(feature))
  }

  _buildContent (feature) {
    let titleText = feature.properties.label

    let valueText = (val, sanitizationThreshold) => {
      if (feature.properties.sanitized && val === 'null' /* WTF MAPBOX? */) {
        return `< ${sanitizationThreshold}`
      }

      return Humanize.compactInteger(val, 1)
    }

    let percVal = () => {
      if (feature.properties.sanitized && !feature.properties.isDiff) {
        return '&ndash; &ndash;'
      }

      return formatMinimalDisplayNumber(feature.properties.mapValue) + '%'
    }

    // Diff view
    if (feature.properties.isDiff) {
      let m1Count = feature.properties.measure_0_value
      let m1MeasureLabel = feature.properties.measure_0_label
      let m1SanitizationThreshold = feature.properties.measure_0_sanitizationThreshold

      let m2Count = feature.properties.measure_1_value
      let m2MeasureLabel = feature.properties.measure_1_label
      let m2SanitizationThreshold = feature.properties.measure_1_sanitizationThreshold

      this.hoverContainer.innerHTML = `
        <h5 class="hover-header">${titleText}</h5>
        <table class="hover-metrics">
            <tr>
                <th>${m1MeasureLabel}: </th>
                <td class="hover-metric">${valueText(m1Count, m1SanitizationThreshold)}</td>
            </tr>
            <tr>
                <th>${m2MeasureLabel}: </th>
                <td>${valueText(m2Count, m2SanitizationThreshold)}</td>
            </tr>
            <tr>
                <th>Difference +/- : </th>
                <td>${percVal()}</td>
            </tr>
        </table>
      `
      // Regular view
    } else {
      let count = feature.properties.measure_0_value
      let measureLabel = feature.properties.measure_0_label
      let sanitizationThreshold = feature.properties.measure_0_sanitizationThreshold

      this.hoverContainer.innerHTML = `
        <h5 class="hover-header">${titleText}</h5>
        <table class="hover-metrics">
          <tr>
            <th>${measureLabel}: </th>
            <td>${valueText(count, sanitizationThreshold)}</td>
          </tr>
          <tr>
            <th>% of Total: </th>
            <td>${percVal()}</td>
          </tr>
        </table>
      `
    }

    return this.hoverContainer.outerHTML
  }
}
