import { Controller } from 'stimulus'
import PubSub from 'pubsub-js'
import _ from 'lodash'
import StateStore from 'lib/StateStore'

// TODO MB should come from data somehow?
const DEFAULT_SELECTION_TEXT = 'United States'

export default class extends Controller {
  static targets = ['measure', 'mapSelection']

  connect () {
    PubSub.subscribe('ntg.state.measures', (msg, measures) => {
      this.setMeasureText(measures.selection)
    })

    PubSub.subscribe('ntg.state.map', (msg, map) => {
      this.setMapSelectionText(map.selection)
    })

    let measuresSelection = StateStore.get('measures')?.selection
    if (measuresSelection) this.setMeasureText(measuresSelection)

    let mapSelection = StateStore.get('map')?.selection
    if (mapSelection) {
      this.setMapSelectionText(mapSelection)
    } else {
      this.mapSelectionTarget.textContent = DEFAULT_SELECTION_TEXT
    }
  }

  get stickyPosition () {
    return this.staticHeadingElement.getBoundingClientRect().bottom + 10
  }

  get staticHeadingElement () {
    return this.element.querySelector('.heading')
  }

  get stickyHeadingElement () {
    return this.element.querySelector('.sticky-header')
  }

  hideShowStickyHeader () {
    if (window.pageYOffset > this.stickyPosition) {
      this.stickyHeadingElement.classList.remove('d-none')
    } else {
      this.stickyHeadingElement.classList.add('d-none')
    }
  }

  setMeasureText (measures) {
    this.measureTargets.forEach(t => {
      t.textContent = measures.map(m => m.label).join(' vs. ')
    })
  }

  setMapSelectionText (selection) {
    if (_.isEmpty(selection)) {
      this.mapSelectionTarget.textContent = DEFAULT_SELECTION_TEXT
    } else {
      this.mapSelectionTarget.textContent = selection.label
    }
  }
}
