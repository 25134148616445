import PubSub from 'pubsub-js'
import _ from 'lodash'

const STORE_KEY = 'ntg_state_store'

class StateStore extends Map {
  constructor () {
    super()

    // LocalStorage State
    Object.entries(this._readStore()).forEach(([key, value]) => this.set(key, value))

    // URL State
    this._deserializeState(this._getURLState()).forEach(([key, value]) => this.set(key, value))
  }

  set (key, value, persist = false) {
    let previousValue = this.get(key)

    if (_.isEqual(previousValue, value)) return

    let result = super.set(key, value)

    if (persist) this._store(key, value)

    PubSub.publish(`ntg.state.${key}`, value)

    return result
  }

  merge (key, value, persist = false) {
    this.set(key, Object.assign({}, this.get(key) || {}, value), persist)
  }

  // TODO remove if we don't need for logging
  get (...args) {
    return super.get(...args)
  }

  toUrl () {
    let url = new URL(window.location.href)
    url.hash = this._serializeState()

    return url.toString()
  }

  _updateURLState () {
    window.history.replaceState(undefined, undefined, `#${this._serializeState()}`)
  }

  _serializeState () {
    return window.btoa(JSON.stringify([...this]))
  }

  _deserializeState (stateString) {
    if (!stateString) return []
    return JSON.parse(window.atob(stateString))
  }

  _getURLState () {
    return window.location.hash.slice(1)
  }

  _store (key, value) {
    let storedData = this._readStore()
    storedData[key] = value
    window.localStorage.setItem(STORE_KEY, JSON.stringify(storedData))
  }

  _readStore () {
    return JSON.parse(window.localStorage.getItem(STORE_KEY) || '{}')
  }
}

export default new StateStore()
