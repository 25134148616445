import _ from 'lodash'
import * as d3 from 'd3'
import * as d3Slider from 'd3-simple-slider'
import * as utils from 'lib/Utils'
import StateStore from 'lib/StateStore'

export class RadarChart {
  isDiff = false

  constructor (container, options) {
    this.container = d3.select(container)
    this._highlightedRefs = []
    this._lockHighlighted = false
    _.assign(this, new RadarConfig(options))
  }

  buildChart (data) {
    this._prepData(data)
    this._prepUIDimensions(this.data[0].ds.length)

    this.slider = d3Slider
      .sliderBottom()
      .min(0.1)
      .max(1)
      .width(this.diameter * 0.6)
      // .ticks(5)
      .tickValues([0.1, 1.0])
      .tickFormat(v => {
        if (v === 0.1) {
          return 'log'
        } else if (v === 1.0) {
          return 'linear'
        } else {
          return ''
        }
      })
      .default(this.scaleExponent)
      .displayValue(false)
      .on('onchange', val => {
        this.scaleExponent = val
        this.render(null, false)
      })

    this.rScale = d3.scalePow()
      .exponent(this.scaleExponent)
      .range([0, this.radius])
      .domain([0, this.maxValue])

    let tooltip = (function (t) {
      if (t.description.trim().length) {
        return `<i class='fa fa-info-circle'
         data-controller='tooltip'
         data-tooltip-classes='description-tooltip'
         data-tooltip-title='${t.title}'
         data-tooltip-body='${t.description}'
         data-action='mouseover->tooltip#show mouseout->tooltip#hide'
      ></i>`
      } else {
        return ''
      }
    }(this))

    this.container.append('h6')
      .html(this.title + tooltip)

    this.chart = this.container
      .append('svg')
      .classed('radar-chart', true)
      .attr('width', this.diameter + this.margin.left + this.margin.right)
      .attr('height', this.diameter + this.margin.top + this.margin.bottom)
      .on('click', () => {
        this._highlightedRefs = []
        this._lockHighlighted = false
        this.render()
      })

    this.chart.append('g')
      .classed('chart-body', true)
      .attr('transform', `translate(${this.radius + this.margin.left}, ${this.radius + this.margin.top})`)

    this.chart.append('g')
      .classed('slider-wrapper', true)
      .attr('transform', `translate(${this.margin.left + ((this.diameter * 0.4) / 2)}, ${this.margin.top + this.diameter + this.sliderSpacingTop})`)
      .call(this.slider)

    this._insertGlowFilter(this.chart)
  }

  render (newData, animate = true) {
    if (newData) { // render may be called without new data due to a resize
      this._prepData(newData)
      this.rScale.domain([0, this.maxValue])

      // TODO do we need rScale for this? Otherwise it could live in _prepData where it belongs
      this.data.forEach(ds => {
        let points = ds.ds.map(utils.withIndex((d, i) => {
          return [
            this.rScale(d.measure.value) * Math.cos(this.angleSlice * i - Math.PI / 2),
            this.rScale(d.measure.value) * Math.sin(this.angleSlice * i - Math.PI / 2)
          ]
        }))
        ds.blobAreaEstimate = d3.polygonArea(points)
      })
    }

    this.rScale.exponent(this.scaleExponent)

    this._prepUIDimensions(this.data[0].ds.length)

    let radarLine = d3.lineRadial()
      .curve(d3.curveCatmullRomClosed.alpha(0.5))
      .radius(d => this.rScale(d.measure.value))
      .angle((d, i) => i * this.angleSlice)

    let chartBody = this.chart.select('g')

    this._drawAxis(chartBody)
    this._drawLegend()

    chartBody.selectAll('.radarWrapper')
      .data(this.data, d => d.ref)
      .join(
        enter => {
          let blobWrapper = enter.append('g')
            .attr('class', 'radarWrapper')

          // Append the backgrounds
          blobWrapper
            .append('path')
            .attr('class', 'radarArea')
            .attr('d', (d) => radarLine(d.ds))
            .attr('opacity', 1.0)
            .style('fill', (d, i) => d.color)
            .style('fill-opacity', this.opacityArea)
            .on('mouseover', (d) => {
              if (this._lockHighlighted) return

              if (!this._highlightedRefs.includes(d.ref)) {
                this._highlightedRefs = [d.ref]
                this.render()
              }
            })
            .on('mouseout', () => {
              if (this._lockHighlighted) return

              this._highlightedRefs = []
              this.render()
            })
            .on('click', d => {
              this._lockHighlight(d)
              d3.event.stopPropagation()
            })

          // Create the outlines
          blobWrapper.append('path')
            .attr('class', 'radarStroke')
            .attr('d', d => radarLine(d.ds))
            .attr('opacity', this.opacityStroke)
            .style('stroke-width', this.strokeWidth + 'px')
            .style('stroke', (d, i) => d.color)
            .style('fill', 'none')
            .style('filter', 'url(#glow)')

          // Append the visible circles
          blobWrapper.selectAll('.visibleRadarCircle')
            .data(d => d.ds)
            .enter().append('circle')
            .attr('class', 'visibleRadarCircle')
            .attr('r', this.dotRadius)
            .attr('cx', (d, i) => this.rScale(d.measure.value) * Math.cos(this.angleSlice * i - Math.PI / 2))
            .attr('cy', (d, i) => this.rScale(d.measure.value) * Math.sin(this.angleSlice * i - Math.PI / 2))
            .attr('opacity', this.opacityCircles)
            .style('fill', '#FAA61A')
            .style('fill-opacity', 0.8)

          return blobWrapper.sort(this._dataSorter)
        },
        update => {
          function maybeAnimate (node) {
            return animate ? node.transition().duration(500) : node
          }

          let visibleRadarCircle = update.selectAll('.visibleRadarCircle')
            .data(d => d.ds)
            .join('circle')

          maybeAnimate(visibleRadarCircle)
            .attr('cx', (d, i) => this.rScale(d.measure.value) * Math.cos(this.angleSlice * i - Math.PI / 2))
            .attr('cy', (d, i) => this.rScale(d.measure.value) * Math.sin(this.angleSlice * i - Math.PI / 2))
            .attr('opacity', d => {
              if (this._highlightedRefs.length === 0) {
                return this.opacityCircles
              } else if (this._highlightedRefs.includes(d.ref)) {
                return this.opacityCirclesHighlighted
              } else {
                return this.opacityCirclesMuted
              }
            })
            .attr('r', d => {
              if (this._highlightedRefs.includes(d.ref)) {
                return this.dotRadius * 2
              } else {
                return this.dotRadius
              }
            })

          maybeAnimate(update.select('.radarStroke'))
            .attr('d', d => radarLine(d.ds))
            .attr('opacity', d => {
              if (this._highlightedRefs.length === 0) {
                return this.opacityStroke
              } else if (this._highlightedRefs.includes(d.ref)) {
                return this.opacityStrokeHightlighted
              } else {
                return this.opacityStrokeMuted
              }
            })

          maybeAnimate(update.select('.radarArea'))
            .attr('d', d => radarLine(d.ds))
            .style('fill-opacity', d => {
              if (this._highlightedRefs.length === 0) {
                return this.opacityArea
              } else if (this._highlightedRefs.includes(d.ref)) {
                return this.opacityAreaHighlighted
              } else {
                return this.opacityAreaMuted
              }
            })

          return update.sort(this._dataSorter)
        }
      )

    // We separate out the hover wrappers so they are always on top and so we can sort them separately to ensure
    // that the selected blob's hovers are reachable
    chartBody.selectAll('.hoverWrapper')
      .data(this.data, d => d.ref)
      .join(
        enter => {
          let hoverWrapper = enter.append('g')
            .attr('class', 'hoverWrapper')

          // Append the hover circles, these are invisible circles drawn over the visible circles but larger so
          // it's easier for the user to hover on them for the tooltip
          hoverWrapper.selectAll('.hoverRadarCircle')
            .data(d => d.ds)
            .enter().append('circle')
            .attr('class', 'hoverRadarCircle')
            .attr('r', this.dotRadius * 2)
            .attr('cx', (d, i) => this.rScale(d.measure.value) * Math.cos(this.angleSlice * i - Math.PI / 2))
            .attr('cy', (d, i) => this.rScale(d.measure.value) * Math.sin(this.angleSlice * i - Math.PI / 2))
            .style('fill-opacity', 0.0)
            .on('mouseover', (d, i, j) => {
              let hoveredData = d
              let hoveredCircle = j[i]

              if (this._lockHighlighted && !this._highlightedRefs.includes(hoveredData.ref)) return

              let circleRect = hoveredCircle.getBoundingClientRect()
              let xCenter = (circleRect.x + circleRect.width / 2 + window.scrollX) + 2
              let yCenter = circleRect.y + circleRect.height / 2 + window.scrollY
              this._showTooltip(xCenter, yCenter, utils.formatMinimalDisplayNumber((hoveredData.measure.value * 100), { max: 6 }) + '%')

              if (!this._highlightedRefs.includes(hoveredData.ref)) {
                this._highlightedRefs = [hoveredData.ref]
                this.render()
              }
            })
            .on('mouseout', (d, j, i) => {
              this._hideTooltip()

              if (!this._lockHighlighted) {
                this._highlightedRefs = []
                this.render()
              }
            })
            .on('click', d => {
              this._lockHighlight(d)
              d3.event.stopPropagation()
            })

          return hoverWrapper.sort(this._dataSorter)
        },
        update => {
          function maybeAnimate (node) {
            return animate ? node.transition().duration(500) : node
          }

          let hoverRadarCircle = update.selectAll('.hoverRadarCircle')
            .data(d => d.ds)
            .join('circle')

          maybeAnimate(hoverRadarCircle)
            .attr('cx', (d, i) => this.rScale(d.measure.value) * Math.cos(this.angleSlice * i - Math.PI / 2))
            .attr('cy', (d, i) => this.rScale(d.measure.value) * Math.sin(this.angleSlice * i - Math.PI / 2))

          return update.sort((a, b) => {
            if (this._highlightedRefs.includes(a.ref)) {
              return 1
            } else if (this._highlightedRefs.includes(b.ref)) {
              return -1
            } else {
              return this._dataSorter(a, b)
            }
          })
        }
      )
  }

  resize () {
    this._prepUIDimensions(this.data[0].ds.length)

    this.rScale.range([0, this.radius])

    this.container.select('svg')
      .attr('width', this.diameter + this.margin.left + this.margin.right)
      .attr('height', this.diameter + this.margin.top + this.margin.bottom)

    this.chart.select('g')
      .attr('transform', `translate(${this.radius + this.margin.left}, ${this.radius + this.margin.top})`)

    this.slider.width(this.diameter * 0.6)
    this.chart.select('g.slider-wrapper')
      .attr('transform', `translate(${this.margin.left + ((this.diameter * 0.4) / 2)}, ${this.margin.top + this.diameter + this.sliderSpacingTop})`)
      .call(this.slider)

    this._drawLegend()

    this.render(null, false)
  }

  teardown () {

  }

  _drawLegend () {
    if (this.chart.select('.legend').size() === 0) {
      this.chart.append('g').classed('legend', true)
    }

    let legend = this.chart.select('.legend')
      .attr('transform', `translate(0, ${this.margin.top + this.legendSpacingTop + this.radius * 2})`)

    legend.selectAll('g')
      .data(_.values(_.groupBy(this.data, 'measure')))
      .join(
        enter => {
          let legendBlocks = enter.append('g').classed('legend-block', true)
            .attr('transform', (d, i) => `translate(0, ${i * this.legendBlockHeight})`)

          // Legend headers
          if (this.isDiff) {
            legendBlocks
              .append('text').classed('measure-header', true)
              .attr('x', 0)
              .attr('y', 0)
              .text(d => d[0].ds[0].measure.label)
          }

          return legendBlocks
        },
        update => {
          if (this.isDiff) {
            update
              .select('.measure-header')
              .text(d => d[0].ds[0].measure.label)
          }
        },
        exit => {
          // disable removing items TODO I'm not sure why this is needed, I think the merge is failing
        }
      )

    let legendBlocks = legend.selectAll('.legend-block')

    let labelPos = this.isDiff ? this.legendLabelOffset : this.diffLegendLabelOffset

    legendBlocks
      .selectAll('g')
      .data(d => d)
      .join(
        enter => {
          let legendValues = enter.append('g')
            .classed('legend-value-block', true)
            .attr('transform', (d, i) => `translate(0, ${labelPos + i * 20})`)
            .on('click', d => {
              this._lockHighlight(d)
              d3.event.stopPropagation()
            })
            .on('mouseover', (d) => {
              if (this._lockHighlighted) return

              if (!this._highlightedRefs.includes(d.ref)) {
                this._highlightedRefs = [d.ref]
                this.render()
              }
            })
            .on('mouseout', () => {
              if (this._lockHighlighted) return

              this._highlightedRefs = []
              this.render()
            })

          legendValues
            .append('rect')
            .classed('legend-value-color', true)
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', this.legendBoxWidth)
            .attr('height', this.legendBoxHeight)
            .attr('fill', d => d.color)
            .attr('stroke', d => d.color)
            .attr('stroke-width', 2)

          legendValues
            .append('text')
            .classed('legend-value-text', true)
            .attr('x', this.legendBoxWidth + 4)
            .attr('y', this.legendBoxHeight + 3)
            .text(d => d.ds[0].dimension.label)

          // Position the legend blocks based on their rendered width
          let xPos = 0
          legendBlocks.attr('transform', (d, i, j) => {
            let translate = `translate(${xPos}, 0)`
            xPos = xPos + j[i].getBBox().width + 20
            return translate
          })

          return legendValues
        },
        update => {
          update.attr('opacity', d => {
            if (!this._lockHighlighted ||
              this._highlightedRefs.length === 0 ||
              this._highlightedRefs.includes(d.ref)) {
              return 1.0
            } else {
              return 0.2
            }
          })

          update.select('.legend-value-text')
            .style('font-weight', d => {
              if (this._highlightedRefs.includes(d.ref)) {
                return 'bold'
              } else {
                return 'normal'
              }
            })
        }
      )

    return legend
  }

  _drawAxis (chartBody) {
    if (chartBody.select('g').size() === 0) {
      chartBody.append('g').attr('class', 'axisWrapper')
    }

    let axisWrapper = chartBody.select('.axisWrapper')

    // Draw the background circles
    axisWrapper.selectAll('.gridCircle')
      .data(d3.range(1, (this.levels + 1)).reverse(), d => d)
      .join(
        enter => {
          enter.append('circle')
            .attr('class', 'gridCircle')
            .attr('r', d => this.rScale(this.maxValue * d / this.levels))
            .style('fill', '#fff')
            .style('stroke', '#CDCDCD')
            .style('stroke-dasharray', '3,3')
        },
        update => {
          update.attr('r', d => this.rScale(this.maxValue * d / this.levels))
        }
      )

    let axis = axisWrapper.selectAll('.axis')
      .data(this.data[0].ds)
      .join(
        enter => {
          let axis = enter.append('g').attr('class', 'axis')

          // Lines
          axis.append('line')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', (d, i) => this.rScale(this.maxValue) * 1.075 * Math.cos(this.angleSlice * i - Math.PI / 2))
            .attr('y2', (d, i) => this.rScale(this.maxValue) * 1.075 * Math.sin(this.angleSlice * i - Math.PI / 2))
            .attr('class', 'line')
            .style('stroke', '#CDCDCD')
            .style('stroke-width', '1px')

          // Labels
          axis.append('text')
            .attr('class', 'label')
            .attr('text-anchor', (d, i) => {
              let rads = Math.cos(this.angleSlice * i - Math.PI / 2)

              if (utils.isEpsilon(rads)) {
                return 'middle'
              } else if (rads > 0) {
                return 'start'
              } else {
                return 'end'
              }
            })
            .attr('dy', '0.35em')
            .attr('x', (d, i) => (this.rScale(this.maxValue) * 1.05 + this.labelFactor) * Math.cos(this.angleSlice * i - Math.PI / 2))
            .attr('y', (d, i) => (this.rScale(this.maxValue) * 1.05 + this.labelFactor) * Math.sin(this.angleSlice * i - Math.PI / 2))
            .text(d => d.shortLabel)
            .call(this._wrap, this.wrapWidth)
            .on('mouseover', d => {
              let dataForAxis = _(this.data).map(rds => {
                let subtypeLabel = rds.ds[0].dimension.label

                return {
                  ref: rds.ref,
                  subtype: rds.subtype,
                  subtypeLabel: subtypeLabel,
                  measure: rds.ds.find(r => r.shortLabel === d.shortLabel).measure
                }
              }).groupBy('measure.label').value()

              let tables = Object.entries(dataForAxis).map(([measureLabel, series, i]) => {
                if (this.isDiff) {
                  return `
                    <div class="hover-label-header">${measureLabel}</div>
                    <table class = "hover-metrics hover-radar">
                      ${series.map(d => {
                         return `
                          <tr>                         
                            <th>${d.subtypeLabel}: </th>
                            <td>${utils.formatMinimalDisplayNumber((d.measure.value * 100), { max: 6 })}%</td>
                          </tr>`
                      }).join('')}
                    </table>
                  `
                } else {
                  return `
                    <table class = "hover-metrics">
                      ${series.map(d => {
                         return `
                          <tr>                         
                            <th>${d.subtypeLabel}: </th>
                            <td>${utils.formatMinimalDisplayNumber((d.measure.value * 100), { max: 6 })}%</td>
                          </tr>`
                      }).join('')}
                    </table>
                  `
                }
              }).join('')

              let content = `
                <h5 class="hover-header">${d.label}</h5>
                ${tables}
              `

              this._showTooltip(d3.event.pageX, d3.event.pageY, content)
            })
            .on('mousemove', () => this._positionTooltip(d3.event.pageX, d3.event.pageY))
            .on('mouseout', () => this._hideTooltip())

          return axis
        },
        update => {
          update.select('line')
            .attr('x2', (d, i) => this.rScale(this.maxValue) * 1.075 * Math.cos(this.angleSlice * i - Math.PI / 2))
            .attr('y2', (d, i) => this.rScale(this.maxValue) * 1.075 * Math.sin(this.angleSlice * i - Math.PI / 2))

          update.select('text')
            .attr('x', (d, i) => (this.rScale(this.maxValue) * 1.05 + this.labelFactor) * Math.cos(this.angleSlice * i - Math.PI / 2))
            .attr('y', (d, i) => (this.rScale(this.maxValue) * 1.05 + this.labelFactor) * Math.sin(this.angleSlice * i - Math.PI / 2))
            .text(d => d.shortLabel)
            .call(this._wrap, this.wrapWidth)
        }
      )
    return axis
  }

  // The general idea here is to have 'blobs' that are smaller on top so they are easier to hover on
  // We do this by measuring the total extent of the data to estimate blob size. This isn't a great heuristic but
  // it's passable for now.
  _dataSorter (a, b) {
    return a.blobAreaEstimate - b.blobAreaEstimate
  }

  _prepData (data) {
    data = JSON.parse(JSON.stringify(data))

    if (!data[0][0]) data = [data]

    // TODO Mark Underp this code so it doesn't loop a dozen times
    if (data[0][0].measures) {
      this.isDiff = true
      data = this._unrollDiffData(data)
    } else {
      this.isDiff = false
    }

    data = data.map(ds => {
      return ds.sort((a, b) => a.order - b.order)
    })

    data.forEach(ds => {
      ds.forEach(d => { d.measure.value = d.measure.value / 10000000.0 })
    })

    if (this.topN) {
      data = utils.topN(data, this.topN)
    } else {
      data.forEach(ds => utils.rotateArray(ds, this.dataRotation))
    }

    // Add a unique identifier to each series so we can merge it more easily during updates.
    data = data.map(utils.withIndex((ds, i) => {
      return {
        ref: `${ds[0].measure.name}_${i}`,
        measure: ds[0].measure.name,
        subtype: i,
        ds: ds
      }
    }))

    data.forEach((ds, i) => {
      ds.color = this.color(i)
      ds.ds.forEach(d => { d.ref = ds.ref })
    })

    this.data = data
    this.maxValue = d3.max(this.data.map(ds => d3.max(ds.ds, d => d.measure.value)))
  }

  _prepUIDimensions (valueCount) {
    // TODO this isn't the best way to handle it
    if (this.isDiff) this.margin = this.diffMargin

    let containerWidth = this.container.node().getBoundingClientRect().width
    this.diameter = Math.min(containerWidth, 550) - this.margin.left - this.margin.right
    this.radius = this.diameter / 2
    this.angleSlice = Math.PI * 2 / valueCount
  }

  _unrollDiffData (data) {
    let measureCount = data[0][0].measures.length

    data = data.map(ds => {
      return _(_.range(0, measureCount)).map(i => {
        return ds.map(d => {
          d = JSON.parse(JSON.stringify(d))
          d.measure = d.measures[i]
          delete d.measures

          return d
        })
      }).value()
    })

    data = _.flatten(data)

    // The goal is to get the data to be ordered by measure first to aid in layout
    return _.flatten(_.values(_.groupBy(data, ds => ds[0].measure.name)))
  }

  // Taken from http://bl.ocks.org/mbostock/7555321
  // Wraps SVG text
  _wrap (text, width) {
    text.each(function () {
      let text = d3.select(this)
      let words = text.text().split(/\s+/).reverse()
      let word
      let line = []
      let lineNumber = 0
      let lineHeight = 1.4 // ems
      let y = text.attr('y')
      let x = text.attr('x')
      let dy = parseFloat(text.attr('dy'))
      let tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('dy', dy + 'em')
      while (word = words.pop()) {
        line.push(word)
        tspan.text(line.join(' '))
        if (tspan.node().getComputedTextLength() > width && line.length > 1) {
          line.pop()
          tspan.text(line.join(' '))
          line = [word]
          tspan = text.append('tspan').attr('x', x).attr('y', y).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word)
        }
      }
    })
  }

  // Filter for the outside glow of the blob outline
  // Referenced in the radarStroke path as 'url(#glow)'
  _insertGlowFilter (chartBody) {
    let filter = chartBody.append('defs').append('filter').attr('id', 'glow')
    filter.append('feGaussianBlur').attr('stdDeviation', '2.5').attr('result', 'coloredBlur')
    let feMerge = filter.append('feMerge')
    feMerge.append('feMergeNode').attr('in', 'coloredBlur')
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic')
  }

  _showTooltip (x, y, text) {
    d3.select('#tooltip')
      .classed('d-none', false)
      .select('#value')
      .html(text)

    this._positionTooltip(x, y)
  }

  _positionTooltip (x, y) {
    let tooltip = document.getElementById('tooltip')
    let ttHeight = tooltip.getBoundingClientRect().height
    let xPosition = x + 20
    let yPosition = y - Math.floor(ttHeight / 2)

    d3.select('#tooltip')
      .style('left', xPosition + 'px')
      .style('top', yPosition + 'px')
  }

  _hideTooltip () {
    d3.select('#tooltip').classed('d-none', true)
  }

  _lockHighlight (d) {
    if (this._lockHighlighted && this._highlightedRefs.includes(d.ref)) {
      if (d3.event.ctrlKey || d3.event.metaKey) {
        this._highlightedRefs = _.without(this._highlightedRefs, d.ref)
      } else {
        this._highlightedRefs = []
      }

      if (this._highlightedRefs.length === 0) {
        this._lockHighlighted = false
      }
    } else {
      // MacOS CMD is classes as meta by D3
      if (d3.event.ctrlKey || d3.event.metaKey) {
        this._highlightedRefs.push(d.ref)
      } else {
        this._highlightedRefs = [d.ref]
      }

      this._lockHighlighted = true
    }

    this.render()
  }
}

export class RadarConfig {
  constructor (options) {
    let presentationMode = StateStore.get('mode')?.name === 'presentation'

    this.margin = _.defaults(options.margin, {
      top: 35,
      right: presentationMode ? 100 : 80,
      bottom: presentationMode ? 160 : 145,
      left: presentationMode ? 100 : 80
    })
    this.diffMargin = _.defaults(options.diffMargin, {
      top: 35,
      right: presentationMode ? 100 : 80,
      bottom: presentationMode ? 185 : 170,
      left: presentationMode ? 100 : 80
    })
    this.color = d3.scaleOrdinal().range(_.get(options, 'color', ['#00A0B0', '#FAA61A', '#7fb36d', '#A14BDE']))
    // How many levels or inner circles should there be drawn
    this.levels = _.get(options, 'levels', 5)
    // The width of the stroke around each blob
    this.strokeWidth = _.get(options, 'strokeWidth', 2)
    // The opacity of the circles of each blob
    this.opacityCircles = _.get(options, 'opacityCircles', 0.35)
    this.opacityCirclesHighlighted = _.get(options, 'opacityCirclesHighlighted', 0.7)
    this.opacityCirclesMuted = _.get(options, 'opacityCirclesMuted', 0.1)
    // The opacity of the area of the blob
    this.opacityArea = _.get(options, 'opacityArea', 0.35)
    this.opacityAreaHighlighted = _.get(options, 'opacityAreaHighlighted', 0.7)
    this.opacityAreaMuted = _.get(options, 'opacityAreaMuted', 0.1)
    // The opacity of the stroke
    this.opacityStroke = _.get(options, 'opacityStroke', 0.35)
    this.opacityStrokeHightlighted = _.get(options, 'opacityStrokeHightlighted', 0.7)
    this.opacityStrokeMuted = _.get(options, 'opacityStrokeMuted', 0.1)
    // The size of the colored circles of each blog
    this.dotRadius = _.get(options, 'dotRadius', 2)
    // The number of pixels after which a label needs to be given a new line
    this.wrapWidth = _.get(options, 'wrapWidth', 60)
    // How much farther than the radius of the outer circle should the labels be placed
    this.labelFactor = _.get(options, 'labelFactor', 16)
    this.title = _.get(options, 'title', '')
    this.description = _.get(options, 'description', '')
    this.dimension = _.get(options, 'dimension')
    this.dimensions = _.get(options, 'dimensions')
    this.sort = _.get(options, 'sort', [])
    this.topN = _.get(options, 'topN', null)
    this.legendSpacingRight = _.get(options, 'legendSpacingRight', presentationMode ? 20 : 12)
    this.legendSpacingTop = _.get(options, 'legendSpacingTop', 115)
    this.sliderSpacingTop = _.get(options, 'sliderSpacingTop', 60)
    this.legendBoxWidth = _.get(options, 'legendBoxWidth', presentationMode ? 15 : 12)
    this.legendBoxHeight = _.get(options, 'legendBoxHeight', presentationMode ? 4 : 2)
    this.legendBlockHeight = _.get(options, 'legendBlockHeight', presentationMode ? 80 : 70)
    this.legendLabelOffset = _.get(options, 'legendLabelOffset', presentationMode ? 14 : 12)
    this.diffLegendLabelOffset = _.get(options, 'diffLegendLabelOffset', 0)
    this.scaleExponent = _.get(options, 'scaleExponent', 1.0)

    // 'Rotate' the data this many steps around the circle. This is useful to position data values with
    // longer labels in positions that can better handle the label length. The default value is manually tuned for
    // existing specialty data.
    // WARNING At present this is only applied when _not_ using topN.
    this.dataRotation = _.get(options, 'dataRotation', 1)
  }
}
