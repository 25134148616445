export default class MapAPIRequest {
  constructor (dataUrl) {
    this._dataUrl = dataUrl

    this._measureNames = null
    this._layer = null
  }

  execute () {
    let url = new URL(this._dataUrl)
    let params = new URLSearchParams()

    this._measureNames.forEach(m => params.append('measures[]', m))

    params.append('layer', this._layer)

    url.search = params.toString()

    return window.fetch(url)
      .then(response => response.json())
      .then(resp => {
        if (resp.error || !Array.isArray(resp.data)) {
          throw new Error(resp.error ?? 'Unknown Error')
        }

        return resp
      })
  }

  measureNames (measureNames) {
    this._measureNames = measureNames

    return this
  }

  layer (layer) {
    this._layer = layer

    return this
  }

  static execute (mapDataRequest) {
    return new MapAPIRequest(mapDataRequest._dataUrl)
      .measureNames(mapDataRequest._measureNames)
      .layer(mapDataRequest._layer)
      .execute()
  }
}
