import mapboxgl from 'mapbox-gl'
import _ from 'lodash'
import { formatMinimalDisplayNumber } from 'lib/Utils'
import polylabel from '@mapbox/polylabel'
import MapData from './map/MapData'
import MapDataRequest from './map/MapDataRequest'
import SubMap from './map/SubMap'
import StateStore from 'lib/StateStore'

mapboxgl.accessToken = 'pk.eyJ1IjoibWVuZGVlbGlzZSIsImEiOiJjazB5a3Z6bXMwMGg0M25sZzFncHlub211In0.9CgIld3KDigw35ZXF7MelA'

// TODO this should come from a poly?
const BOUNDS = {
  US: [
    [-127, 24],
    [-62, 50]
  ],
  AK: [
    [-170.15625, 51.72702815704774],
    [-127.61718749999999, 71.85622888185527]
  ],
  HI: [
    [-161.03759765625, 18.542116654448996],
    [-154.22607421875, 22.573438264572406]
  ]
}

const ZOOM_MAP = {
  1: 'low',
  2: 'low',
  3: 'medium_low',
  4: 'medium_low',
  5: 'medium',
  6: 'medium',
  7: 'medium_high',
  8: 'high',
  9: 'high'
}

export default class Map {
  selectedMeasures = null

  hoveredStateId = null
  clickedStateId = null

  measures = []

  allReady = false

  constructor (container, dataUrl, controller) {
    this.controller = controller
    this.dataUrl = dataUrl

    // https://docs.mapbox.com/mapbox-gl-js/api/#popup
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 10
    })

    let mapState = StateStore.get('map')

    this.map = new mapboxgl.Map({
      container: container,
      style: 'mapbox://styles/mendeelise/ck2fe0ud501ld1cjyep033km2',
      bounds: mapState?.bounds ?? BOUNDS.US,
      trackResize: false,
      maxZoom: 9
    })

    this.map.scrollZoom.disable()
    this.map.touchZoomRotate.disableRotation()
    this.map.dragRotate.disable()

    this._mapData = new MapData()

    this.nonContiguousMaps = this._setupNonContiguousMaps()
    this.allMaps = [this.map, ...this.nonContiguousMaps.map(m => m.map)]

    this.debouncedGeoUpdate = _.debounce(() => {
      this.updateGeo().then((update) => { if (update) this._update() })
    }, 500, { maxWait: 1000 })

    this.resizeMapPanel = _.debounce(this._resizeMapPanel.bind(this), 200, { maxWait: 500 })

    this._setupShortcutKeys()
  }

  async setup () {
    this.currentZoom = Math.round(this.map.getZoom())

    Promise.all([
      this.setupMap(this.map, this.popup),
      ...this.nonContiguousMaps.map(m => this.setupMap(m.map, m.popup)),
      new Promise(resolve => { this._selectionReadyResolve = resolve }), // Resolves when measures are first selected
      new Promise(resolve => { this._measuresReadyResolve = resolve }) // Resolves when a layer is first selected
    ]).then(() => {
      this.clickedStateId = StateStore.get('map')?.selection?.id

      if (this.clickedStateId) {
        this.allMaps.forEach(m => m.setFeatureState({ source: 'geo', id: this.clickedStateId }, { clicked: true }))
      }

      this.map.on('zoom', () => {
        if (this.allReady) this.debouncedGeoUpdate()

        StateStore.merge('map', {
          bounds: this.map.getBounds().toArray()
        })
      })

      this.map.on('move', () => {
        if (this.allReady) this.debouncedGeoUpdate()
      })

      this.map.on('moveend', (e) => {
        // The mapbox API fires events for API calls and some of the setup calls tend to fire off events that we
        // don't want to record in the state store. This checks if the event was user initiated by looking for
        // a wrapped originalEvent.
        if (e.originalEvent) {
          StateStore.merge('map', {
            bounds: this.map.getBounds().toArray()
          })
        }
      })

      this.allReady = true
      this.updateAll()
      this.map.getContainer().classList.add('map-loaded')
    })

    this.map.addControl(new mapboxgl.NavigationControl())

    this.map.setMinZoom(this.map.cameraForBounds(BOUNDS.US).zoom)

    this.resizeMapPanel(false)

    let resetButton = document.getElementById('reset-map')
    resetButton.addEventListener('click', () => this.map.fitBounds(BOUNDS.US, { linear: true }))
  }

  setupMap (map, popup) {
    return new Promise((resolve) => {
      map.on('load', () => {
        map.addSource('geo', {
          type: 'geojson',
          data: {
            type: 'GeometryCollection',
            geometries: []
          }
        })

        map.addLayer({
          id: 'geo_fills',
          type: 'fill',
          source: 'geo',
          layout: {},
          paint: {
            'fill-color': ['get', 'fillColor'],
            'fill-opacity': [
              'case', ['boolean', ['feature-state', 'hover'], false], 1, 1
            ]
          }
        })

        map.addLayer({
          id: 'geo_outlines',
          type: 'line',
          source: 'geo',
          layout: {},
          paint: {
            'line-color': [
              'case', ['boolean', ['==', ['get', 'mapValue'], 0], false], '#CCCCCC', 'rgba(0,0,0,.06)'
            ],
            'line-width': 1
          }
        })

        map.addLayer({
          id: 'geo_highlight',
          type: 'line',
          source: 'geo',
          layout: {},
          paint: {
            'line-color': [
              'case',
              ['any', ['boolean', ['feature-state', 'clicked'], false], ['boolean', ['feature-state', 'hover'], false]],
              'yellow',
              'rgba(255,255,255,0)'
            ],
            'line-width': [
              'case',
              ['any', ['boolean', ['feature-state', 'clicked'], false], ['boolean', ['feature-state', 'hover'], false]],
              2,
              0
            ]
          }
        })

        // We update allMaps in these functions because a geo may span into sub maps, e.g. AK and HI are in the west
        // census region along with all the western mainland US

        map.on('mousemove', 'geo_fills', e => {
          let hoveredFeature = e.features[0]

          if (e.features.length > 0 && hoveredFeature.id !== this.hoveredStateId) {
            if (this.hoveredStateId) {
              this.allMaps.forEach(m => m.setFeatureState({ source: 'geo', id: this.hoveredStateId }, { hover: false }))
            }
            this.hoveredStateId = hoveredFeature.id
            this.allMaps.forEach(m => m.setFeatureState({ source: 'geo', id: this.hoveredStateId }, { hover: true }))
            this.controller.featureHovered(hoveredFeature, map, popup, e)
          }
        })

        map.on('mouseleave', 'geo_fills', (e) => {
          if (this.hoveredStateId) {
            this.allMaps.forEach(m => m.setFeatureState({ source: 'geo', id: this.hoveredStateId }, { hover: false }))
          }
          this.hoveredStateId = null
          this.nonContiguousMaps.forEach(m => m.popup.remove())
          this.popup.remove()
        })

        map.on('click', 'geo_fills', e => {
          if (e.features.length > 0) {
            let clickedFeature = e.features[0]

            // Disable clicking on sanitized data
            if (clickedFeature.properties.sanitized) return

            // the second click to a Geo should reset the audience insights to US metrics
            if (this.clickedStateId === clickedFeature.id) {
              this.allMaps.forEach(m => m.setFeatureState({ source: 'geo', id: this.clickedStateId }, { clicked: false }))
              this.clickedStateId = null
              this.controller.featureClicked(clickedFeature, true)
              return
            }

            if (this.clickedStateId) {
              this.allMaps.forEach(m => m.setFeatureState({ source: 'geo', id: this.clickedStateId }, { clicked: false }))
            }

            this.clickedStateId = clickedFeature.id
            this.allMaps.forEach(m => m.setFeatureState({ source: 'geo', id: this.clickedStateId }, { clicked: true }))
            this.controller.featureClicked(clickedFeature, false)
          }
        })

        map.on('click', e => {
          let clickedFeatures = map.queryRenderedFeatures(e.point, { layers: ['geo_fills'] })
          if (clickedFeatures.length === 0 && this.clickedStateId) {
            this.allMaps.forEach(m => m.setFeatureState({ source: 'geo', id: this.clickedStateId }, { clicked: false }))
            this.clickedStateId = null
            this.controller.featureClicked(null, true)
          }
        })

        resolve()
      })
    })
  }

  measuresSelected (measures) {
    if (!this.selectedMeasures) {
      this.selectedMeasures = measures
      this._measuresReadyResolve()
    } else {
      this.selectedMeasures = measures
      this.updateData().then(() => this._update())
    }
  }

  layerSelected (layer) {
    if (!this.selectedLayer) {
      this.selectedLayer = layer
      this._selectionReadyResolve()
    } else {
      this.selectedLayer = layer
      this.updateAll()
    }
  }

  isDiff () {
    return this.selectedMeasures.length === 2
  }

  updateAll () {
    this.nonContiguousMaps.forEach(m => m.updateAll(this.selectedLayer, this.selectedMeasures))

    return Promise.all([
      this.updateGeo(),
      this.updateData()
    ]).then(() => this._update())
  }

  async updateGeo () {
    let zoomLevel = Math.round(this.map.getZoom())
    let zoomLevelChanged = zoomLevel !== this.currentZoom

    let currentBounds = [
      [this.map.getBounds().getSouthWest().lng, this.map.getBounds().getSouthWest().lat],
      [this.map.getBounds().getNorthEast().lng, this.map.getBounds().getNorthEast().lat]
    ]

    let clippedBounds = this._clipBounds(currentBounds, BOUNDS.US)

    if (clippedBounds == null) return false

    let req = new MapDataRequest(this.dataUrl)
      .resolution(ZOOM_MAP[zoomLevel])
      .bounds(mapboxgl.LngLat.convert(clippedBounds[0]), mapboxgl.LngLat.convert(clippedBounds[1]))
      .layer(this.selectedLayer.name)

    let dataUpdated = await this._mapData.executeGeos(req)
    let updateNeeded = dataUpdated || zoomLevelChanged

    this.currentZoom = zoomLevel

    return updateNeeded
  }

  updateData () {
    this.nonContiguousMaps.forEach(m => m.updateAll(this.selectedLayer, this.selectedMeasures))

    let req = new MapDataRequest(this.dataUrl)
      .measureNames(this.selectedMeasures.map(m => m.name))
      .layer(this.selectedLayer.name)

    return this._mapData.executeData(req)
  }

  _clipBounds (a, b) {
    let [[x1, y1], [x2, y2]] = a
    let [[x3, y3], [x4, y4]] = b

    let x5 = Math.max(x1, x3)
    let y5 = Math.max(y1, y3)
    let x6 = Math.min(x2, x4)
    let y6 = Math.min(y2, y4)

    if (x5 > x6 || y5 > y6) {
      return null
    } else {
      return [
        [x6, y5],
        [x5, y6]
      ]
    }
  }

  _update () {
    let [geoData, dataSummary] = this._mapData.mapData(this.selectedLayer.name, ZOOM_MAP[this.currentZoom])

    this.drawLegend({
      min: dataSummary.minValue,
      quintiles: _.zip(dataSummary.colorScale, dataSummary.domain),
      max: dataSummary.maxValue
    })

    this.map.getSource('geo').setData({
      type: 'FeatureCollection',
      features: geoData
    })
  }

  drawLegend ({ min, quintiles, max }) {
    // TODO can we figure out a way to not build HTML in the Javascript using innerHTML.

    let legend = document.querySelector('#map-overlay-legend')
    let titleEle = document.createElement('h6')
    let colorContainer = document.createElement('div')
    let labelContainer = document.createElement('div')
    colorContainer.setAttribute('class', 'legend-colors')
    labelContainer.setAttribute('class', 'legend-labels')
    legend.innerHTML = ''

    if (this.isDiff()) {
      titleEle.innerHTML = 'Percentage Difference +/-'
    } else {
      titleEle.innerHTML = 'Percent of Total'
    }

    legend.appendChild(titleEle)
    legend.appendChild(colorContainer)
    legend.appendChild(labelContainer)

    quintiles.forEach(([color, value], i) => {
      let colorEle = document.createElement('div')
      colorEle.style.backgroundColor = color
      colorContainer.appendChild(colorEle)

      if (i === 0 || i === (quintiles.length - 1)) {
        let minMax = i === 0 ? min : max
        let val = value === 0 ? 0 : minMax
        let labelEle = document.createElement('label')
        labelEle.innerHTML = formatMinimalDisplayNumber(val) + '%'
        labelContainer.appendChild(labelEle)
      }
    })
  }

  _resizeMapPanel () {
    let previousBounds = this.map.getBounds().toArray()

    this.map.resize()

    let wholeUSCamera = this.map.cameraForBounds(BOUNDS.US)
    let newCamera = this.map.cameraForBounds(previousBounds)

    this.map.setMinZoom(wholeUSCamera.zoom)
    this.map.jumpTo(newCamera)

    this.nonContiguousMaps.forEach(m => m.resetToBounds())
  }

  _setupShortcutKeys () {
    document.addEventListener('keydown', (event) => {
      if (event.shiftKey && event.metaKey && event.key === 'u') {
        let randomSelect = () => {
          let feature = _.sample(this.map.queryRenderedFeatures({ layers: ['geo_fills'] }))

          let geom

          if (feature.geometry.type === 'Polygon') {
            geom = feature.geometry.coordinates
          } else if (feature.geometry.type === 'MultiPolygon') {
            geom = feature.geometry.coordinates[0]
          } else {
            console.err('Unknown geometry type', feature.geometry)
          }

          let featurePoint = polylabel(geom)

          this.map.fire('click', {
            lngLat: featurePoint,
            point: this.map.project(featurePoint)
          })

          this._demoTimeout = window.setTimeout(randomSelect, 2000)
        }

        if (this._demoTimeout) {
          window.clearTimeout(this._demoTimeout)
          this._demoTimeout = null
        } else {
          randomSelect()
        }
      }
    })
  }

  _setupNonContiguousMaps () {
    let alaska = new SubMap({
      container: document.getElementById('map-overlay-alaska'),
      mapTileUrl: 'mapbox://styles/mendeelise/ck2dtzpwg1qgv1dn3nf6f8pyi',
      bounds: BOUNDS.AK,
      dataUrl: this.dataUrl
    })

    let hawaii = new SubMap({
      container: document.getElementById('map-overlay-hawaii'),
      mapTileUrl: 'mapbox://styles/mendeelise/ck2fe0ud501ld1cjyep033km2',
      bounds: BOUNDS.HI,
      dataUrl: this.dataUrl
    })

    return [alaska, hawaii]
  }
}
